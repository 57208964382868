import { configureStore } from '@reduxjs/toolkit';
import ReduxThunk from 'redux-thunk';

import { reducers } from './reducers';

import { IS_DEVELOPMENT } from 'constants/environment.constant';

let store;
export const getStore = () => store;

export const Store = () => {
    const middleware = [ReduxThunk];

    store = configureStore({
        reducer: reducers(),
        middleware,
        devTools: IS_DEVELOPMENT,
        enhancers: [],
        preloadedState: {},
    });

    return store;
};

import { createSlice } from '@reduxjs/toolkit';

import { initialBlogState } from './initialState';

export const blogSlice = createSlice({
    name: 'blog',
    initialState: initialBlogState(),
    reducers: {
        setLoading(state, action) {
            state.isBlogLoading = action.payload;
        },
        setMoreLoading(state, action) {
            state.isMoreLoading = action.payload;
        },
        setBlogItems(state, action) {
            state.blogItems = {
                ...action.payload,
                data: [...state.blogItems.data, ...action.payload.data],
            };
        },
        setBlogItemsByCategory(state, action) {
            state.blogItems = action.payload;
        },
        setBannerData(state, action) {
            state.bannerData = action.payload;
        },
        setBlogCategories(state, action) {
            state.blogCategories = action.payload;
        },
        setSelectedCategory(state, action) {
            state.selectedCategory = action.payload;
        },
    },
});

import React from 'react';
import PropTypes from 'prop-types';

import { S3_BUCKET_URL } from 'constants/api.constants';

import { useSelector } from 'react-redux';
import { globalSel } from 'store/global';

import { useTheme } from '@mui/material';
import { Box } from 'style-guide';

import mainBackground from 'assets/images/main-background.png';

const Main = ({ children }) => {
    const theme = useTheme();
    const dashboardBanner = useSelector(globalSel.dashboardBannerSelector);
    const backgroundImage = dashboardBanner.value?.background
        ? S3_BUCKET_URL + dashboardBanner.value?.background
        : mainBackground;

    return (
        <Box
            component="main"
            width="calc(100% - 240px)"
            position="relative"
            flexGrow={1}
            pt={15}
            pl={60}
            height={1}
            minHeight="100vh"
            sx={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundPosition: 'center top',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    pl: 0,
                },
            }}
        >
            <Box px={['20px', '30px']} py={[5, 8]} height={1}>
                {children}
            </Box>
        </Box>
    );
};

Main.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Main;

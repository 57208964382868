import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material';

const StyledIcon = styled('i')(({ size, color }) => ({
    fontSize: `${size}px`,
    color: `${color}`,
    '&[class^="icon-"], &[class*=" icon-"]': {
        /* use !important to prevent issues with browser extensions that change fonts */
        fontFamily: 'icomoon !important',
        speak: 'never',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: 1,

        /* Better Font Rendering =========== */
        webkitFontSmoothing: 'antialiased',
        mozOsxFontSmoothing: 'grayscale',
    },
    '&.icon-checkbox:before': {
        content: '"\\e92f"',
    },
    '&.icon-scopely:before': {
        content: '"\\e92e"',
    },
    '&.icon-event:before': {
        content: '"\\e92c"',
    },
    '&.icon-facebook:before': {
        content: '"\\e931"',
    },
    '&.icon-timer:before': {
        content: '"\\e926"',
    },
    '&.icon-copy-writer:before': {
        content: '"\\e927"',
    },
    '&.icon-copy:before': {
        content: '"\\e928"',
    },
    '&.icon-menu-hamburger:before': {
        content: '"\\e929"',
    },
    '&.icon-arrow-down:before': {
        content: '"\\e900"',
    },
    '&.icon-arrow-forward:before': {
        content: '"\\e901"',
    },
    '&.icon-arrow-up:before': {
        content: '"\\e902"',
    },
    '&.icon-blog:before': {
        content: '"\\e903"',
    },
    '&.icon-calendar:before': {
        content: '"\\e904"',
    },
    '&.icon-clock:before': {
        content: '"\\e905"',
    },
    '&.icon-close:before': {
        content: '"\\e906"',
    },
    '&.icon-dashboard:before': {
        content: '"\\e907"',
    },
    '&.icon-discord:before': {
        content: '"\\e908"',
    },
    '&.icon-dollar:before': {
        content: '"\\e909"',
    },
    '&.icon-exclamation:before': {
        content: '"\\e90a"',
    },
    '&.icon-facebook-square:before': {
        content: '"\\e90b"',
    },
    '&.icon-faction:before': {
        content: '"\\e90c"',
    },
    '&.icon-hand-up:before': {
        content: '"\\e90d"',
    },
    '&.icon-info:before': {
        content: '"\\e90e"',
    },
    '&.icon-instagram:before': {
        content: '"\\e90f"',
    },
    '&.icon-arrow-left:before': {
        content: '"\\e910"',
    },
    '&.icon-living-skull:before': {
        content: '"\\e911"',
    },
    '&.icon-medal:before': {
        content: '"\\e912"',
    },
    '&.icon-remove:before': {
        content: '"\\e913"',
    },
    '&.icon-motivation:before': {
        content: '"\\e914"',
    },
    '&.icon-place-1:before': {
        content: '"\\e915"',
    },
    '&.icon-place-2:before': {
        content: '"\\e916"',
    },
    '&.icon-place-3:before': {
        content: '"\\e917"',
    },
    '&.icon-player-up:before': {
        content: '"\\e918"',
    },
    '&.icon-add:before': {
        content: '"\\e919"',
    },
    '&.icon-point-up:before': {
        content: '"\\e91a"',
    },
    '&.icon-arrow-right:before': {
        content: '"\\e91b"',
    },
    '&.icon-search:before': {
        content: '"\\e91c"',
    },
    '&.icon-featured-offers:before': {
        content: '"\\e91d"',
    },
    '&.icon-skull:before': {
        content: '"\\e91e"',
    },
    '&.icon-target:before': {
        content: '"\\e91f"',
    },
    '&.icon-total-loses:before': {
        content: '"\\e920"',
    },
    '&.icon-twitter:before': {
        content: '"\\e921"',
    },
    '&.icon-user:before': {
        content: '"\\e922"',
    },
    '&.icon-user-plus:before': {
        content: '"\\e923"',
    },
    '&.icon-youtube:before': {
        content: '"\\e924"',
    },
}));

const Icon = ({ size, name, color }) => {
    return <StyledIcon size={size} color={color} className={`icon-${name}`} />;
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
};

Icon.defaultProps = {
    size: 24,
    color: 'inherit',
};

export default Icon;

import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useDispatch } from 'react-redux';

import { useMediaQuery, useTheme } from '@mui/material';
import { Button } from 'style-guide';
import { APP_REDIRECT_URI } from 'constants/api.constants';
import { globalOp } from 'store/global';
import { getStorageItem, setStorageItem } from 'helpers/localStorage';
import { useTranslation } from 'hooks';

const LoginWithFacebook = ({ appId, disableMobileRedirect, isLoading }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));
    const loginProcessing = getStorageItem('loginProcessing');
    const [t] = useTranslation();

    const handleFacebookResponse = (response) => {
        setStorageItem('loginProcessing', 'withFacebook');

        if (response?.accessToken && !response.error) {
            dispatch(globalOp.loginAsync(response.accessToken, 'facebook'));
            dispatch(globalOp.getGlobalConfigs());
            dispatch(globalOp.handleCloseModal());
        }
    };

    return (
        <FacebookLogin
            appId={appId}
            disableMobileRedirect={disableMobileRedirect}
            callback={handleFacebookResponse}
            redirectUri={loginProcessing === 'withFacebook' ? APP_REDIRECT_URI : null}
            render={(renderProps) => {
                return (
                    <Button
                        icon="facebook"
                        isLoading={loginProcessing === 'withFacebook' ? isLoading : null}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={renderProps.onClick}
                        type="facebook"
                        hasBackground={false}
                        styles={{ textTransform: 'initial' }}
                    >
                        {t('sign.in.with.facebook')}
                    </Button>
                );
            }}
        />
    );
};

LoginWithFacebook.propTypes = {
    appId: PropTypes.string,
    disableMobileRedirect: PropTypes.bool,
    isLoading: PropTypes.bool,
};

export default LoginWithFacebook;

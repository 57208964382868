export const gtagEvent = ({
    action,
    event_category,
    event_label,
    value,
    userId,
    offerId,
    offerName,
    purchaseLimit,
    startDate,
    endDate,
    sku,
    ...props
}) => {
    if (window.gtag) {
        return window.gtag('event', action, {
            event_category: event_category || '',
            event_label: event_label || '',
            value: value || '',
            User_ID: userId || '',
            Offer_ID: offerId || '',
            Offer_Name: offerName || '',
            offer_max_purchase: purchaseLimit || '',
            offer_start_date: startDate || '',
            offer_end_date: endDate || '',
            SKU_code: sku || '',
            ...props,
        });
    }
};

import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';

import {
    BUGSNAG_API_KEY,
    API_ENV,
    DATADOGROOM_APPLICATION_ID,
    DATADOGROOM_CLIENT_TOKEN,
    DATADOGROOM_SERVICE,
} from './constants/api.constants';

import { Provider } from 'react-redux';
import { Store } from 'store/configureStore';

import App from 'App';

Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
});

Bugsnag.notify(new Error('Test error'));

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

// datadog configs
datadogRum.init({
    applicationId: DATADOGROOM_APPLICATION_ID,
    clientToken: DATADOGROOM_CLIENT_TOKEN,
    service: DATADOGROOM_SERVICE,
    env: API_ENV,
    site: 'datadoghq.com',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
});

if (API_ENV !== 'prod') {
    datadogRum.startSessionReplayRecording();

    const head = document.querySelector('head');
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'robots');
    meta.setAttribute('content', 'noindex');
    head.appendChild(meta);
}

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={Store()}>
            <App />
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
);

import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

import { Box } from 'style-guide';

import { addLanguageCodeToLink } from 'helpers/utils';

import logo from 'assets/images/TWD_logo.png';

const AppLogo = ({ isLeftSide, setOpenLeftSidebar }) => {
    return (
        <NavLink exact to={addLanguageCodeToLink('/')}>
            {isLeftSide ? (
                <Box
                    component="img"
                    src={logo}
                    width={239}
                    px={12}
                    py={8}
                    sx={{
                        cursor: 'pointer',
                    }}
                    alt="TWD logo"
                    onClick={() => setOpenLeftSidebar(false)}
                />
            ) : (
                <Box
                    component="img"
                    src={logo}
                    width={60}
                    py={4}
                    sx={{
                        cursor: 'pointer',
                    }}
                    alt="TWD-logo"
                />
            )}
        </NavLink>
    );
};

AppLogo.defaultProps = {
    isLeftSide: false,
    setOpenLeftSidebar: () => {},
};

AppLogo.propTypes = {
    isLeftSide: PropTypes.bool,
    setOpenLeftSidebar: PropTypes.func,
};

export default AppLogo;

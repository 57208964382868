import { createSelector } from 'reselect';

const factionSelector = (state) => state.faction;

const isLoadingSelector = createSelector([factionSelector], (faction) => faction.isLoading);
const factionDataSelector = createSelector([factionSelector], (faction) => faction.faction);
const isFactionCheckInLoadingSelector = createSelector(
    [factionSelector],
    (faction) => faction.isFactionCheckInLoading,
);
const factionCheckInSelector = createSelector(
    [factionSelector],
    (faction) => faction.factionCheckIn,
);

export const factionSel = {
    isLoadingSelector,
    factionDataSelector,
    factionCheckInSelector,
    isFactionCheckInLoadingSelector,
};

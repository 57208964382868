import React from 'react';
import { useTheme } from '@mui/material';
import { StyledCopyWriter } from './CopyWriter.styled';
import { Box, Icon, Typography } from 'style-guide';
import { useTranslation } from 'hooks';

const CopyWriter = () => {
    const theme = useTheme();
    const [t] = useTranslation();

    return (
        <StyledCopyWriter>
            <Box borderTop={`1px solid ${theme.palette.black['300']}`} pt={5}>
                <Typography variant="p10" color={theme.palette.text[700]}>
                    <Icon name="copy-writer" size={12} />
                    {t('copy.writer.text')}
                </Typography>
            </Box>
        </StyledCopyWriter>
    );
};

export default CopyWriter;

export const typography = {
    headline: { fontSize: '54px', lineHeight: '72px' },
    h1: { fontSize: '32px', lineHeight: 1.5 },
    h2: { fontSize: '28px', lineHeight: 1.5 },
    h3: { fontSize: '24px', lineHeight: 1.5 },
    h4: { fontSize: '20px', lineHeight: 1.5 },
    p18: { fontSize: '18px', lineHeight: 1.5 },
    p16: { fontSize: '16px', lineHeight: 1.5 },
    p14: { fontSize: '14px', lineHeight: 1.5 },
    p12: { fontSize: '12px', lineHeight: 1.5 },
    p10: { fontSize: '10px', lineHeight: 1.5 },
};

import { createSelector } from 'reselect';

const playerSelector = (state) => state.player;

const isLoadingSelector = createSelector([playerSelector], (player) => player.isLoading);
const isPlayerInfoLoadingSelector = createSelector(
    [playerSelector],
    (player) => player.isPlayerInfoLoading,
);
const playerDataSelector = createSelector([playerSelector], (player) => player.player);
const playerInfoSelector = createSelector([playerSelector], (player) => player.playerInfo);

export const playerSel = {
    isLoadingSelector,
    playerDataSelector,
    playerInfoSelector,
    isPlayerInfoLoadingSelector,
};

import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

import { Typography, Box } from 'style-guide';
import { StyledConnectionLost } from './ConnectionLost.styled';
import { useTranslation } from 'hooks';

const ConnectionLost = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const isMain = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <StyledConnectionLost
            className="WP-ConnectionLost-root"
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Box
                sx={{
                    zIndex: 2,
                    width: 1,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    mt: [25, 30, 36],
                }}
            >
                <Box mb={[3, 5]} textAlign="center" mx={10}>
                    <Typography
                        variant={isMain ? 'headline' : 'h3'}
                        className="WP-ConnectionLost-title"
                        color={theme.palette.text[300]}
                    >
                        {t('walkers.have.attacked')}
                    </Typography>
                </Box>
                <Typography
                    variant={isMain ? 'h1' : 'h3'}
                    className="WP-ConnectionLost-subtitle"
                    color={theme.palette.text[500]}
                >
                    {t('well.back.online')}
                </Typography>
            </Box>
            <Box className="WP-ConnectionLost-bg" />
        </StyledConnectionLost>
    );
};

export default ConnectionLost;

import { createSelector } from 'reselect';

const livingSkullSelector = (state) => state.livingSkulls;

const isLoadingSelector = createSelector(
    [livingSkullSelector],
    (livingSkulls) => livingSkulls.isLivingSkullsLoading,
);
const livingSkullsItemsSelector = createSelector(
    [livingSkullSelector],
    (livingSkulls) => livingSkulls.livingSkullsItems,
);

export const livingSkullsSel = {
    isLoadingSelector,
    livingSkullsItemsSelector,
};

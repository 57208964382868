import { styled } from '@mui/material/styles';

export const StyledLeftSidebar = styled('aside')(({ theme }) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    width: 240,
    height: '100vh',
    overflow: 'auto',
    borderRight: `1px solid ${theme.palette.black[300]}`,
    zIndex: 1200,
    backgroundColor: theme.palette.black[700],
    transition: 'transform 200ms ease-out',
    scrollbarWidth: 'none',

    [theme.breakpoints.down('md')]: {
        transform: 'translateX(-240px)',
        '&.show': {
            transform: 'translateX(0)',
        },
    },
}));

import { createTheme } from '@mui/material/styles';
import { typography } from './typography';
import { palette } from './palette';

const theme = createTheme({
    themeName: 'TWD_THEME',
    palette,
    spacing: 4,
    typography,
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1280,
            lg: 1440,
            xl: 1920,
        },
    },
    components: {
        MuiSkeleton: {
            styleOverrides: {
                text: {
                    backgroundColor: palette.black[500],
                },
                rectangular: {
                    backgroundColor: palette.black[500],
                },
                circular: {
                    backgroundColor: palette.black[500],
                },
                wave: {
                    '&::after': {
                        backgroundImage: palette.gradient.skeleton,
                    },
                },
            },
        },
    },
});

export default theme;

import React, { useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'hooks';

import { Box, Typography, Button, Link } from 'style-guide';
import { StyledNotFoundPage } from './NotFoundPage.styled';
import peopleImage from 'assets/images/404-people.png';
import peopleImageMobile from 'assets/images/404-people-mobile.png';

const NotFoundPage = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const customMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        window?.DD_RUM?.addError('404-visit-count', window?.history?.state?.as);
    }, []);
    return (
        <StyledNotFoundPage>
            <Box
                component="img"
                src={customMobile ? peopleImageMobile : peopleImage}
                alt="not-found"
                sx={{
                    width: [1, 'auto'],
                    maxHeight: 564,
                    maxWidth: 1,
                    mb: [8, 12, 10],
                }}
            />
            <Box>
                <Typography
                    color={theme.palette.text[600]}
                    variant={isMobile ? 'h3' : isTablet ? 'h2' : 'h1'}
                >
                    {t('not.found.survivor')}
                </Typography>
                <Box display="flex" my={[6, 8, 12]} justifyContent="center">
                    <Link href="/">
                        <Button type="secondary" size="medium" icon="arrow-left">
                            {t('back.home')}
                        </Button>
                    </Link>
                </Box>
            </Box>
        </StyledNotFoundPage>
    );
};

export default NotFoundPage;

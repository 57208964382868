import { LANGUAGES, PAGES } from 'constants/project.constants';
import { getStorageItem } from 'helpers/localStorage';

export const initialTranslationsState = {
    selectedLanguage: LANGUAGES[getStorageItem('language')] || LANGUAGES.en,
    translations: {},
    loading: PAGES.reduce(
        (prev, curr) => ({
            ...prev,
            [curr]: true,
        }),
        {
            common: true,
        },
    ),
};

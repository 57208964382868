import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'style-guide';

const Backdrop = ({ open, backgroundColor, zIndex, children, onClick, top }) => {
    return open ? (
        <Box
            position="fixed"
            top={top}
            right={0}
            bottom={0}
            left={0}
            opacity={0.6}
            backgroundColor={backgroundColor}
            zIndex={zIndex}
            onClick={onClick}
        >
            <Box position="relative" zIndex={zIndex + 1}>
                {children}
            </Box>
        </Box>
    ) : null;
};

Backdrop.defaultProps = {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1200,
    open: false,
    onClick: () => {},
    children: null,
    top: 0,
};

Backdrop.propTypes = {
    children: PropTypes.node,
    zIndex: PropTypes.number,
    open: PropTypes.bool,
    backgroundColor: PropTypes.string,
    onClick: PropTypes.func,
    top: PropTypes.number,
};

export default Backdrop;

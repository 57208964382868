import React from 'react';
import { useTranslation } from 'hooks';

import { useTheme, useMediaQuery } from '@mui/material';
import { Box, Typography } from 'style-guide';
import { StyledMaintenance } from './Maintenance.styled';

const Maintenance = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <StyledMaintenance
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Box className="WP-MaintenanceComponent-bg" />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 1,
                    zIndex: 1,
                }}
            >
                <Box className="WP-MaintenanceComponent-center-bg" />
                <Box
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: 1,
                        zIndex: 9,
                    }}
                >
                    <Box className="WP-MaintenanceComponent-center-circle" />
                    <Typography
                        variant={isMobile ? 'h4' : isTablet ? 'h2' : 'h1'}
                        color={theme.palette.text[600]}
                        sx={{ mb: 5, mt: 11 }}
                    >
                        {t('portal.under.maintenance')}
                    </Typography>
                    <Typography variant={isMobile ? 'p16' : 'h3'} color={theme.palette.text[800]}>
                        {t('check.back.soon')}
                    </Typography>
                </Box>
            </Box>
        </StyledMaintenance>
    );
};

export default Maintenance;

import React, { memo } from 'react';

import { useTheme } from '@mui/material';
import { Box, Typography } from 'style-guide';
import FactionCheckInModalCard from './FactionCheckInModalCard';

import { useTranslation } from 'hooks';

import howItWorksIcon1 from 'assets/images/faction-check-in-modal-icon-1.svg';
import howItWorksIcon2 from 'assets/images/faction-check-in-modal-icon-2.svg';
import howItWorksIcon3 from 'assets/images/faction-check-in-modal-icon-3.svg';

const FactionCheckInModal = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const {
        palette: { text },
    } = theme;

    return (
        <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            maxHeight={1}
            sx={{
                overflowY: 'auto',
            }}
        >
            <Typography variant="h4" fontWeight="bold" color={text[300]} mb={6}>
                {t('how.it.works')}
            </Typography>
            <Box display="flex" flexDirection={['column', 'row']} gap={[4, 5]}>
                <FactionCheckInModalCard image={howItWorksIcon1} message="how.it.works.1" />
                <FactionCheckInModalCard image={howItWorksIcon2} message="how.it.works.2" />
                <FactionCheckInModalCard image={howItWorksIcon3} message="how.it.works.3" />
            </Box>
        </Box>
    );
};

export default memo(FactionCheckInModal);

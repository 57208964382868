export const getNavigationList = (user) => {
    return [
        {
            id: 1,
            name: 'dashboard',
            href: '/',
            iconClassName: 'dashboard',
            globalConfig: 'dashboard',
        },
        {
            id: 2,
            name: 'my.profile',
            href:
                !user && window.location.pathname.includes('logged-out')
                    ? '/players/profile/logged-out'
                    : `/players/profile/${user?.FriendCode}/realm/${user?.CurrentRealm}`,
            iconClassName: 'user',
            globalConfig: 'playerProfile',
        },
        {
            id: 3,
            name: 'my.faction',
            href:
                user?.Guild?.Id && user?.CurrentRealm
                    ? `/faction/${user?.Guild?.Id}/realm/${user?.CurrentRealm}`
                    : window.location.pathname.includes('logged-out')
                    ? '/faction/logged-out'
                    : '/faction/not-joined',
            iconClassName: 'faction',
            globalConfig: 'factionProfile',
            hasBadge: user && user.HasActiveFactionCheckInEvent && !user.IsCheckedIn,
        },
        {
            id: 4,
            name: 'blog',
            href: '/blog',
            iconClassName: 'blog',
            globalConfig: 'blog',
        },
        {
            id: 5,
            name: 'calendar',
            href: '/calendar',
            iconClassName: 'calendar',
            globalConfig: 'calendar',
        },
        {
            id: 6,
            name: 'featured.offers',
            href: '/featured-offers',
            iconClassName: 'featured-offers',
            globalConfig: 'featuredOffers',
            hasBadge: true,
        },
        {
            id: 7,
            name: 'events',
            href: '/events',
            iconClassName: 'event',
            globalConfig: 'events',
            hasBadge: true,
        },
        {
            id: 8,
            name: 'living.skulls.shop',
            href: '/living-skulls-shop',
            iconClassName: 'living-skull',
            globalConfig: 'livingSkullsShop',
        },
    ];
};

import { createSlice } from '@reduxjs/toolkit';

import { initialTranslationsState } from './initialState';

export const translationsSlice = createSlice({
    name: 'translations',
    initialState: initialTranslationsState,
    reducers: {
        setTranslations(state, action) {
            state.translations = {
                ...state.translations,
                ...action.payload,
            };
        },
        setLoading(state, action) {
            state.loading[action.payload.page] = action.payload.loading;
        },
        setSelectedLanguage(state, action) {
            state.selectedLanguage = action.payload;
        },
    },
});

import { createSlice } from '@reduxjs/toolkit';

import { initialEventsState } from './initialState';

export const eventsSlice = createSlice({
    name: 'events',
    initialState: initialEventsState(),
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setEvents(state, action) {
            state.events = action.payload;
        },
        setMilestoneLoading(state, action) {
            state.milestoneLoading = action.payload;
        },
    },
});

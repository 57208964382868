import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useTheme, useMediaQuery } from '@mui/material';
import { Box, Icon, Typography } from 'style-guide';

import { useTranslation } from 'hooks';
import { hexToRgba } from 'helpers/hexToRgba';

const FactionCheckInModalCard = ({ icon, image, message }) => {
    const [t] = useTranslation();
    const theme = useTheme();
    const {
        palette: { black, text, yellow },
        breakpoints,
    } = theme;

    const isMobile = useMediaQuery(breakpoints.down('sm'));

    return (
        <Box
            display="flex"
            alignItems="center"
            flexDirection={['row', 'column']}
            maxWidth={[1, 180]}
            minHeight={[145, 268]}
            borderRadius="10px"
            backgroundColor={hexToRgba(black[700], 0.6)}
            border={`1px solid ${black[300]}`}
            px={4}
            pt={[0, 5]}
            pb={[0, 6]}
            sx={{
                backdropFilter: 'blur(20px)',
                flex: '1 0 0%',
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                minWidth={[48, 56]}
                minHeight={[48, 56]}
                backgroundColor={hexToRgba(text[400], 0.25)}
                borderRadius="50%"
                mb={[0, 5]}
                mr={[4, 0]}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={42}
                    height={42}
                    backgroundColor={text[300]}
                    borderRadius="50%"
                >
                    {icon ? (
                        <Icon size={20} name={icon} color={yellow[700]} />
                    ) : (
                        <Box component="img" alt="how-it-works-icon" src={image} />
                    )}
                </Box>
            </Box>
            <Typography variant={isMobile ? 'p12' : 'p14'} color={text[600]}>
                {t(message)}
            </Typography>
        </Box>
    );
};

FactionCheckInModalCard.propTypes = {
    icon: PropTypes.string,
    image: PropTypes.string,
    message: PropTypes.string.isRequired,
};

FactionCheckInModalCard.defaultProps = {
    icon: '',
    image: '',
};

export default memo(FactionCheckInModalCard);

import React from 'react';

import { useTheme } from '@mui/material';
import { Box, Typography } from 'style-guide';

import { useTranslation } from 'hooks';

import survivorClubModalIcon from 'assets/images/survival-club-modal.png';

const SurvivorsClubModal = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const {
        palette: { text },
    } = theme;

    return (
        <Box display="flex" flexDirection="column" alignItems="center" maxWidth={320}>
            <Box
                component="img"
                src={survivorClubModalIcon}
                alt="survivors-club-not-subscribe-image"
                width={100}
                height={100}
                mb={8}
            />
            <Typography fontWeight="bold" variant="h4" color={text[300]} textAlign="center" mb={2}>
                {t('survivors.club.exclusive')}
            </Typography>
            <Typography color={text[600]} textAlign="center" mb={10}>
                {t('survival.modal.description')}
            </Typography>
        </Box>
    );
};

export default SurvivorsClubModal;

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { globalOp, globalSel } from 'store/global';

import { Dialog, useTheme } from '@mui/material';
import { Box } from 'style-guide';
import LoginModal from './components/LoginModal';
import SuccessfulModal from './components/SuccessfulModal';
import NotLinkedAccountModal from './components/NotLinkedAccountModal';
import NotEnoughLivingSkullsModal from './components/NotEnoughLivingSkullsModal';
import SurvivorsClubModal from './components/SurvivorsClubModal';
import ModalCloseIcon from './components/ModalCloseButton';
import FactionCheckInModal from './components/FactionCheckInModal';

import modalPopupBg from 'assets/images/modal-popup-bg.png';

const Modal = () => {
    const theme = useTheme();
    const {
        palette: { grey },
    } = theme;

    const dispatch = useDispatch();
    const modal = useSelector(globalSel.modalSelector);

    const modalGenerator = (variant, message) => {
        switch (variant) {
            case 'login':
                return <LoginModal />;
            case 'reward-success':
                return <SuccessfulModal message={message} />;
            case 'not-linked-account':
                return <NotLinkedAccountModal />;
            case 'not-enough-living-skulls':
                return <NotEnoughLivingSkullsModal />;
            case 'survivals-club-member':
                return <SurvivorsClubModal />;
            case 'faction-check-in':
                return <FactionCheckInModal />;
            default:
                return null;
        }
    };

    const handleCloseModal = () => dispatch(globalOp.handleCloseModal());

    return (
        modal.open && (
            <Dialog
                scroll="paper"
                open={modal.open}
                onClose={handleCloseModal}
                sx={{
                    '.MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        overflowY: 'inherit',
                    },
                    '.MuiBackdrop-root': {
                        backdropFilter: 'blur(20px)',
                        backgroundColor: 'rgba(33, 33, 33, 0.7)',
                    },
                }}
            >
                <Box
                    sx={{
                        minWidth: [1, 400],
                        minHeight: [1, 390],
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        alignItems: 'center',
                        px: [5, 8],
                        py: [7, 10],
                        backgroundImage: `url(${modalPopupBg})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '10px',
                        border: `2px solid ${grey[800]}`,
                    }}
                >
                    <ModalCloseIcon />
                    {modalGenerator(modal.variant, modal.message)}
                </Box>
            </Dialog>
        )
    );
};

export default Modal;

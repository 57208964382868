export const notAvailableRouters = (config) => {
    return Object.keys(config)
        .filter((key) => !config[key])
        .map((configKey) => {
            switch (configKey) {
                case 'featuredOffers':
                    return ['/featured-offers', '/featured-offers'];
                case 'calendar':
                    return ['/calendar'];
                case 'playerProfile':
                    return ['/players/profile/:playerId/realm/:realmId'];
                case 'factionProfile':
                    return ['/faction/:guildId'];
                case 'webshop':
                    return ['/living-skulls-shop'];
                case 'events':
                    return ['/events'];
                case 'blog':
                    return ['/blog'];
                case 'dashboard':
                    return ['/'];
                default:
                    return '';
            }
        })
        .flat();
};

export const pageMaintenanceModeRenderer = (page, maintenanceMode) => {
    switch (page) {
        case '/':
            return !maintenanceMode.dashboard;
        case '/calendar':
            return !maintenanceMode.calendar;
        case '/blog':
            return !maintenanceMode.blog;
        case '/players/profile/:playerId/realm/:realmId':
            return !maintenanceMode.playerProfile;
        case '/faction/:guildId':
            return !maintenanceMode.factionProfile;
        case '/living-skulls-shop':
            return !maintenanceMode.webshop;
        case '/events':
            return !maintenanceMode.events;
        case '/featured-offers':
            return !maintenanceMode.featuredOffers;
        default:
            return false;
    }
};

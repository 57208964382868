import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery, useTheme } from '@mui/material';
import { Box, Icon, Typography } from 'style-guide';

const AvatarName = ({
    image,
    handleImageClick,
    name,
    handleNameClick,
    subName,
    handleSubNameClick,
    isHeader,
}) => {
    const theme = useTheme();
    const isCustomMobile = useMediaQuery('(max-width: 375px)');

    const [imageNotFoundId, setImageNotFoundId] = useState(false);

    return (
        <Box display="flex" alignItems="center" flexDirection={isHeader ? 'row-reverse' : null}>
            {imageNotFoundId ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={32}
                    height={32}
                    mx={3}
                    borderRadius="50%"
                    sx={{
                        border: `1px solid ${theme.palette.text[400]}`,
                    }}
                    onClick={handleImageClick}
                >
                    <Icon size={18} name="user" color={theme.palette.text[400]} />
                </Box>
            ) : (
                <Box
                    component="img"
                    mr={isCustomMobile ? 1 : 3}
                    onError={() => setImageNotFoundId(true)}
                    onClick={handleImageClick}
                    src={image}
                    alt="avatar"
                    sx={{
                        border: `1px solid ${theme.palette.text[400]}`,
                        width: 32,
                        height: 32,
                        display: 'inline-block',
                        ml: 3,
                        borderRadius: 50,
                    }}
                />
            )}
            <Box display="flex" flexDirection="column" alignItems={isHeader ? 'flex-end' : null}>
                <Box maxWidth={isCustomMobile ? 160 : 90} onClick={handleNameClick}>
                    <Typography className="text-truncate" variant="p12" fontWeight="bold">
                        {name}
                    </Typography>
                </Box>
                {subName && (
                    <Box onClick={handleSubNameClick}>
                        <Typography variant="p10" color={theme.palette.text[600]}>
                            {subName}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

AvatarName.defaultProps = {
    image: '',
    name: '',
    subName: '',
    isHeader: false,
    handleImageClick: () => {},
    handleNameClick: () => {},
    handleSubNameClick: () => {},
};

AvatarName.propTypes = {
    image: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    handleImageClick: PropTypes.func,
    handleNameClick: PropTypes.func,
    handleSubNameClick: PropTypes.func,
    isHeader: PropTypes.bool,
};

export default AvatarName;

import React from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'hooks';
import { Button } from 'style-guide';

import { scopelyId } from 'voluum';

import { getStorageItem, setStorageItem } from 'helpers/localStorage';

const LoginWithScopely = ({ isLoading }) => {
    const loginProcessing = getStorageItem('loginProcessing');
    const theme = useTheme();
    const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));
    const [t] = useTranslation();

    const redirectToLogin = () => {
        setStorageItem('loginProcessing', 'withScopely');
        scopelyId.login();
    };

    return (
        <Button
            size={isMobile ? 'small' : 'medium'}
            isLoading={loginProcessing === 'withScopely' ? isLoading : null}
            onClick={redirectToLogin}
            type="scopely"
            icon="scopely"
            hasBackground={false}
            styles={{ textTransform: 'initial' }}
        >
            {t('sign.in.with.scopely')}
        </Button>
    );
};

LoginWithScopely.propTypes = {
    isLoading: PropTypes.bool,
};

export default LoginWithScopely;

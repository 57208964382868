import { createSlice } from '@reduxjs/toolkit';

import { initialGlobalState } from './initialState';

export const globalSlice = createSlice({
    name: 'global',
    initialState: initialGlobalState(),
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setCurrentUser(state, action) {
            state.currentUser = action.payload;
        },
        setAuthenticated(state, action) {
            state.authenticated = action.payload;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload;
        },
        setModal(state, action) {
            state.modal = action.payload;
        },
        setGlobalLeaderboards(state, action) {
            state.globalLeaderboards = action.payload;
        },
        setLeaderboardsLoading(state, action) {
            state.isLeaderboardsLoading = action.payload;
        },
        setPortalConfigs(state, action) {
            state.globalConfig = action.payload;
        },
        setFirstLogin(state, action) {
            state.firstLogin = action.payload;
        },
        setRealmLoading(state, action) {
            state.isRealmLoading = action.payload;
        },
        setDashboardBanner(state, action) {
            state.dashboardBanner = action.payload;
        },
        setUserLivingSkulls(state, action) {
            state.currentUser = {
                ...state.currentUser,
                LivingSkulls: action.payload,
            };
        },
    },
});

import { fetch } from 'services';

import { globalOp } from 'store/global';

import { blogSlice } from './blogSlice';
import { getStore } from '../configureStore';

const getBlogItems = (page = 1, currentCategory = 'All') => {
    const { setLoading, setBlogItems, setMoreLoading, setBannerData } = blogSlice.actions;
    const isLoadMore = page !== 1;

    return async (dispatch) => {
        isLoadMore ? dispatch(setMoreLoading(true)) : dispatch(setLoading(true));
        const getStateBanner = (state) => {
            return state.blog.bannerData;
        };
        try {
            const { data: blog } = await fetch({
                endPoint: `/news`,
                body: {
                    page,
                    category: currentCategory,
                    limit: 12,
                },
            });
            const store = getStore();
            const bannerDate = getStateBanner(store.getState());
            if (!bannerDate.length) {
                dispatch(setBannerData(blog.data));
            }
            dispatch(setBlogItems(blog));
        } catch (error) {
            globalOp.handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'something.went.wrong',
            });
        }

        isLoadMore ? dispatch(setMoreLoading(false)) : dispatch(setLoading(false));
    };
};

const getBlogByCategory = (selectedCategory) => {
    const { setBlogItemsByCategory, setMoreLoading } = blogSlice.actions;
    return async (dispatch) => {
        dispatch(setMoreLoading(true));

        try {
            const { data: blog } = await fetch({
                endPoint: `/news`,
                body: {
                    page: 1,
                    limit: 12,
                    category: selectedCategory,
                },
            });

            dispatch(setBlogItemsByCategory(blog));
        } catch (error) {
            globalOp.handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'something.went.wrong',
            });
        }

        dispatch(setMoreLoading(false));
    };
};

const getBlogCategory = () => {
    const { setBlogCategories } = blogSlice.actions;

    return async (dispatch) => {
        try {
            const { data: blogCategories } = await fetch({
                endPoint: `/news/categories`,
            });

            dispatch(setBlogCategories(blogCategories));
        } catch (error) {
            globalOp.handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'something.went.wrong',
            });
        }
    };
};

export const blogOp = {
    getBlogItems,
    getBlogCategory,
    getBlogByCategory,
};

import { styled } from '@mui/material/styles';
import { Box } from 'style-guide';

export const StyledCopyWriter = styled(Box)(({ theme }) => ({
    position: 'relative',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginTop: theme.spacing(16),

    '& p': {
        '&:hover': {
            color: theme.palette.text[300],
        },
    },
    '& i': {
        marginRight: theme.spacing(1),
    },
}));

export const initialGlobalState = () => {
    return {
        authenticated: false,
        currentUser: null,
        isLoading: true,
        globalLeaderboards: null,
        isLeaderboardsLoading: true,
        globalConfig: {
            global: true,
            dashboard: true,
            featuredOffers: true,
            webshop: true,
            playerProfile: true,
            factionProfile: true,
            blog: true,
            calendar: true,
            events: true,
        },
        maintenance: {
            global: true,
            dashboard: true,
            featuredOffers: true,
            webshop: true,
            playerProfile: true,
            factionProfile: true,
            blog: true,
            calendar: true,
            events: true,
        },
        snackbar: {
            open: false,
            variant: '',
        },
        modal: {
            open: false,
            variant: '',
        },
        firstLogin: false,
        isRealmLoading: false,
        dashboardBanner: {},
    };
};

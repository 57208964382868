import { styled } from '@mui/material/styles';
import { Box } from 'style-guide';

export const StyledMenu = styled(Box)(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.black[500],
    borderRadius: '6px',
    zIndex: 1200,
    '&.bottom': {
        '&:after': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            top: '-7px',
            right: '6px',
            left: 'auto',
            transform: 'translate(-50%, -50%)',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: `15px solid ${theme.palette.black[500]}`,
            [theme.breakpoints.down('sm')]: {
                right: '-7px',
            },
        },
    },
    '&.top': {
        '&:after': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            bottom: '-20px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: `15px solid ${theme.palette.black[500]}`,
        },
    },
    '&.left': {
        '&:after': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            top: '50%',
            left: '-6px',
            transform: 'translate(-50%, -50%)',
            borderTop: '10px solid transparent',
            borderBottom: '10px solid transparent',
            borderRight: `15px solid ${theme.palette.black[500]}`,
        },
    },
    '&.right': {
        '&:after': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            top: '50%',
            right: '-20px',
            transform: 'translate(-50%, -50%)',
            borderTop: '10px solid transparent',
            borderBottom: '10px solid transparent',
            borderLeft: `15px solid ${theme.palette.black[500]}`,
        },
    },
}));

import React, { useEffect } from 'react';
import { useTranslation } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { translationsOp, translationsSel } from 'store/translations';

import { StyledLeftSidebarFooter } from './LeftSidebarFooter.styled';
import { useTheme } from '@mui/material';
import { Box, Icon, Link, Typography, DropDown } from 'style-guide';

import CopyWriter from '../CopyWriter';
import Subscribe from '../Subscribe';

import { SCOPELY_URLS } from 'constants/api.constants';
import { LANGUAGES } from 'constants/project.constants';
import { socialLinkList } from './socialLinkList';

import buttonEffect from 'assets/images/discordbg.svg';
import { ReactComponent as PlayMarket } from 'assets/images/google.svg';
import { ReactComponent as AppStore } from 'assets/images/appstore.svg';

const LeftSidebarFooter = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedLanguage = useSelector(translationsSel.selectedLanguageSelector);

    const changeLanguage = (language) => {
        dispatch(translationsOp.setLanguage(language));
    };

    useEffect(() => {
        // eslint-disable-next-line
        if (window.reloadOTBanner) {
            // eslint-disable-next-line
            window.reloadOTBanner();
        }
    }, []);

    return (
        <StyledLeftSidebarFooter>
            <Box display="flex" alignItems="center" gap={5} ml={5}>
                <Link href="https://apps.apple.com/app/id969316884?mt=8" blank>
                    <AppStore width="90px" />
                </Link>
                <Link
                    href="https://play.google.com/store/apps/details?id=com.scopely.headshot&referrer=adjust_reftag%3DcQQGkWvvWnqgS%26utm_source%3DWalkingDeadRTSBlog-Android"
                    blank
                >
                    <PlayMarket width="90px" />
                </Link>
            </Box>
            <Box pt={16} pb={8} px={5} className="useTermsPolicy">
                <Link blank href={SCOPELY_URLS.TERMS}>
                    <Typography uppercase variant="p14" color={theme.palette.text[600]}>
                        {t('terms.of.service')}
                    </Typography>
                </Link>
                <Box mt={5}>
                    <Link href={SCOPELY_URLS.PRIVACY_POLICY}>
                        <Typography variant="p14" color={theme.palette.text[600]} uppercase>
                            {t('privacy.policy')}
                        </Typography>
                    </Link>
                </Box>
                <Box mt={5}>
                    {/*eslint-disable-next-line*/}
                    <a href="#" id="ot-sdk-link" className="ot-sdk-show-settings">
                        Cookies Settings
                    </a>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" px={5} className="social-links">
                {socialLinkList.map((links) => {
                    return (
                        <Link key={links.linkId} href={links.linkHref} blank>
                            <Icon name={links.iconName} color={theme.palette.grey[800]} />
                        </Link>
                    );
                })}
            </Box>
            <Box
                px={5}
                my={8}
                sx={[
                    {
                        '& span': {
                            whiteSpace: 'normal',
                            textTransform: 'initial',
                        },
                    },
                    {
                        '& i': {
                            mr: 4,
                        },
                    },
                ]}
            >
                <Link href="https://discord.com/invite/twdrts" blank>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            px: 3,
                            py: 2,
                            backgroundImage: `url(${buttonEffect})`,
                            backgroundColor: theme.palette.black[500],
                            border: `1px solid ${theme.palette.black[300]}`,
                            borderRadius: '6px',
                        }}
                    >
                        <Box>
                            <Icon color={theme.palette.text[600]} name="discord" />
                        </Box>
                        <Typography variant="p14" span color={theme.palette.text[300]}>
                            {t('join.our.discord')}
                        </Typography>
                    </Box>
                </Link>
            </Box>
            <Box mb={10} px={5}>
                <DropDown
                    list={Object.values(LANGUAGES)}
                    selected={selectedLanguage}
                    onClickHandler={(lang) => changeLanguage(lang)}
                    openUp
                />
            </Box>
            <Subscribe />
            <CopyWriter />
        </StyledLeftSidebarFooter>
    );
};

export default LeftSidebarFooter;

import { styled } from '@mui/material/styles';
import { Box } from 'style-guide';

export const StyledLeftSidebarFooter = styled(Box)(({ theme }) => ({
    paddingBottom: theme.spacing(8),

    '& .useTermsPolicy': {
        '& p': {
            transition: 'color 200ms ease-out',
            ' &:hover': {
                color: theme.palette.text[400],
            },
        },
    },
    '& .social-links': {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(8),
        },

        '& a': {
            marginRight: theme.spacing(5),
            transition: 'color 200ms ease-out',

            '&:last-child': {
                marginRight: 0,
            },

            '&:hover': {
                '& i': {
                    color: theme.palette.text[300],
                },
            },
        },
    },
    '#ot-sdk-link.ot-sdk-show-settings': {
        color: `${theme.palette.text[600]}`,
        fontSize: '14px',
        textTransform: 'uppercase',
        transition: 'color 200ms ease-out',
        ' &:hover': {
            color: `${theme.palette.text[300]}`,
        },
    },
}));

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'hooks';

import { Link, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { globalOp, globalSel } from 'store/global';

import PlayerProfileInfoSkeleton from './Skeletons';
import { IMAGES_URL } from 'constants/api.constants';

import { useMediaQuery, useTheme, Divider } from '@mui/material';
import { Icon, Typography, Box, Backdrop } from 'style-guide';
import Menu from '../Menu';

import { addLanguageCodeToLink, thousandsFormatterWithCommas } from 'helpers/utils';
import Avatar from 'views/components/Avatar';
import AvatarName from 'views/components/AvatarName';

const PlayerProfileInfo = ({ infoDropdownOpen, setInfoDropdownOpen }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [t] = useTranslation();
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const currentUser = useSelector(globalSel.currentUserSelector);
    const isRealmLoading = useSelector(globalSel.isRealmLoadingSelector);
    const [submenuOpened, setSubmenuOpened] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState('');
    const openSubmenu = () => setSubmenuOpened(true);

    const handleLogout = () => {
        document.body.style['overflow-y'] = 'auto';

        dispatch(globalOp.logout());
    };

    const handleRegionSelect = (realmName, realmId) => {
        if (realmName && selectedRegion && realmName !== selectedRegion) {
            setSelectedRegion(realmName);
            dispatch(globalOp.setCurrentRealm(realmId));
            if (window.location.pathname.includes('/players/profile')) {
                history.push(
                    addLanguageCodeToLink(
                        `/players/profile/${currentUser?.FriendCode}/realm/${realmId}`,
                    ),
                );
            }
        }

        setSubmenuOpened(false);
        setInfoDropdownOpen(false);
    };

    const menuList = [
        {
            id: 1,
            content: `${selectedRegion} ${t('region')}`,
            callback: openSubmenu,
            hasSubmenu: true,
        },
        {
            id: 2,
            content: t('logout'),
            callback: handleLogout,
        },
    ];

    useEffect(() => {
        if (currentUser) {
            const currentRealm = currentUser.Realms.find(
                (realm) => +realm.Id === +currentUser.CurrentRealm,
            );

            currentRealm && setSelectedRegion(currentRealm.Name);
        }
    }, [currentUser]);

    const handleClose = (e) => {
        if (e.type.indexOf('touch') === 0) {
            return;
        }

        if (!isMobile) {
            setInfoDropdownOpen(false);
            setSubmenuOpened(false);
        }
    };

    useEffect(() => {
        if (infoDropdownOpen && isMobile) {
            document.body.style['overflow-y'] = 'hidden';
        } else {
            document.body.style['overflow-y'] = 'auto';
        }
    }, [infoDropdownOpen, isMobile]);

    const infoDownToggle = () => setInfoDropdownOpen((infoDown) => !infoDown);

    const nameLink = (
        <Box
            component={Link}
            sx={{
                color: theme.palette.text[300],
                ':hover': {
                    color: theme.palette.text[400],
                },
            }}
            className="text-truncate"
            to={addLanguageCodeToLink(
                `/players/profile/${currentUser?.FriendCode}/realm/${currentUser?.CurrentRealm}`,
            )}
        >
            {currentUser.Name}
        </Box>
    );

    const imageLink = currentUser.AvatarIcon
        ? IMAGES_URL + currentUser.AvatarIcon + '?size=large'
        : '';

    return (
        <>
            {isRealmLoading ? (
                <PlayerProfileInfoSkeleton />
            ) : (
                <Box
                    sx={{ display: 'flex', alignItems: 'center', position: ['unset', 'relative'] }}
                >
                    <Box display="flex" py={1} px={1}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pr: 1 }}>
                            <Icon name="skull" size={20} color={theme.palette.yellow[700]} />

                            <Typography
                                variant="p12"
                                fontWeight="bold"
                                color={theme.palette.yellow[700]}
                            >
                                {thousandsFormatterWithCommas(currentUser?.LivingSkulls)}
                            </Typography>
                        </Box>

                        {isMobile ? (
                            <Avatar image={imageLink} />
                        ) : (
                            <Box display="flex" alignItems="center">
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    sx={{
                                        borderColor: theme.palette.black[300],
                                        height: 16,
                                        mx: 3,
                                    }}
                                />
                                <AvatarName
                                    name={nameLink}
                                    image={imageLink}
                                    subName={selectedRegion + ` ${t('region')}`}
                                    isHeader
                                />
                            </Box>
                        )}

                        <Box
                            className="cursor-pointer"
                            display="flex"
                            alignItems="center"
                            pl={4}
                            onClick={infoDownToggle}
                        >
                            <Icon name={infoDropdownOpen ? 'arrow-up' : 'arrow-down'} size={8} />
                        </Box>
                    </Box>
                    {infoDropdownOpen && (
                        <>
                            {isMobile && (
                                <Backdrop
                                    open={infoDropdownOpen}
                                    zIndex={1200}
                                    onClick={() => setInfoDropdownOpen(false)}
                                />
                            )}
                            <Box
                                sx={{
                                    zIndex: 2000,
                                    position: 'absolute',
                                    left: [8, 36],
                                    top: [65, 70],
                                    maxWidth: ['100%', '290px'],
                                    width: '100%',
                                    px: 5,
                                }}
                            >
                                <Menu
                                    hasEffect
                                    menuContent={menuList}
                                    onCloseHandler={(e) => handleClose(e)}
                                    submenuContent={currentUser.Realms}
                                    submenuOpened={submenuOpened}
                                    onBackHandler={() => setSubmenuOpened(false)}
                                    handleRegionSelect={handleRegionSelect}
                                    variant="bottom"
                                    currentRealm={currentUser.CurrentRealm}
                                    userName={currentUser.Name}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            )}
        </>
    );
};

PlayerProfileInfo.propTypes = {
    infoDropdownOpen: PropTypes.bool,
    setInfoDropdownOpen: PropTypes.func,
};

export default PlayerProfileInfo;

import { styled } from '@mui/material/styles';
import { Box } from 'style-guide';

export const StyledSubscribe = styled(Box)(({ theme }) => ({
    '& .WP-Subscribe-terms-policy-wrapper': {
        '& a': {
            color: theme.palette.yellow[700],
        },
    },
}));

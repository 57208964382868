import { fetch } from 'services';

import { globalOp } from 'store/global';

import { playerSlice } from './playerSlice';

const getPlayer = (userId, realmId) => {
    const { setLoading, setPlayer } = playerSlice.actions;

    return async (dispatch) => {
        if (!Number(userId) && !Number(realmId)) return;
        dispatch(setLoading(true));

        try {
            const { data: player } = await fetch({
                endPoint: `/players/profile/${userId}/realm/${realmId}`,
            });

            dispatch(setPlayer(player));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
            globalOp.handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'error.on.player',
            });
        }
    };
};

export const playerOp = {
    getPlayer,
};

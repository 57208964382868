import { styled } from '@mui/material/styles';
import { Box } from 'style-guide';

export const StyledDropDown = styled(Box)(({ theme }) => ({
    position: 'relative',

    '& .dropdown-wrapper': {
        '&:hover': {
            backgroundColor: theme.palette.black[400],
        },
    },
    '& .active-element': {
        '& p': {
            color: theme.palette.text[300],
        },
    },

    '& .dropdown-list': {
        '&::-webkit-scrollbar': {
            display: 'block',
            width: '2px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.black[500],
            borderRadius: '10px',
            marginTop: '6px',
            marginBottom: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.grey[800],
            borderRadius: '10px',
        },

        '& ul': {
            '& li': {
                listStyle: 'none',

                '&:hover': {
                    backgroundColor: theme.palette.black[400],
                },
            },
        },
    },
}));

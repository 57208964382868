import { fetch } from 'services';

import { globalOp } from 'store/global';

import { featuredOffersSlice } from './featuredOffersSlice';

import { gtagEvent } from 'helpers/GA4Helper';

const getFeaturedOffersItems = (isLoggedIn) => {
    const { setLoading, setFeaturedOffersItems } = featuredOffersSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const endPoint = isLoggedIn
                ? `/offers/featured-offers`
                : `/offers/featured-offers/global`;
            const { data: featuredOffers } = await fetch({ endPoint });

            dispatch(setFeaturedOffersItems(featuredOffers));
        } catch (error) {
            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'something.went.wrong',
                }),
            );
        }

        dispatch(setLoading(false));
    };
};

const getExclusiveOffers = (isLoggedIn) => {
    const { setExclusiveOffersLoading, setExclusiveOffers } = featuredOffersSlice.actions;

    return async (dispatch) => {
        dispatch(setExclusiveOffersLoading(true));

        try {
            const endPoint = isLoggedIn ? `/offers/exclusive` : `/offers/exclusive/global`;
            const { data: exclusiveOffers } = await fetch({ endPoint });

            dispatch(setExclusiveOffers(exclusiveOffers));
        } catch (error) {
            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'something.went.wrong',
                }),
            );
        }

        dispatch(setExclusiveOffersLoading(false));
    };
};

const getXsollaToken = (offerData, setPurchaseItem) => {
    const { setXsollaToken } = featuredOffersSlice.actions;

    return async (dispatch) => {
        try {
            const { data: payments } = await fetch({
                endPoint: '/payments/get-token',
                method: 'POST',
                body: offerData,
            });

            dispatch(setXsollaToken(payments.token));
        } catch (error) {
            setPurchaseItem('');
            setXsollaToken('');

            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'something.went.wrong',
                }),
            );
        }
    };
};

const freePurchase = ({ offerData, livingSkullsTotal, currentUser, endpointType }) => {
    return async (dispatch) => {
        try {
            await fetch({
                endPoint: `/payments/purchase/${endpointType}`,
                method: 'POST',
                body: offerData,
            });

            dispatch(globalOp.updateUserBalance());
            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: 'reward-success',
                }),
            );
            livingSkullsTotal &&
                gtagEvent({
                    action: `living_skulls_purchased/${offerData.shopItem}`,
                    event_category: 'Living Skulls Purchased',
                    event_label: 'living_skulls_purchased',
                    value: `livingSkulls-total-${livingSkullsTotal}`,
                    userId: `${currentUser?.UserId}`,
                    offerId: `${offerData?.shopItem}`,
                    offerName: `${livingSkullsTotal}`,
                });
            livingSkullsTotal &&
                window?.DD_RUM?.addAction('currency-sourced-Livingskulls', { ...offerData });
        } catch (error) {
            if (
                error.status === 422 &&
                error.data.message === "You don't have enough living skulls"
            ) {
                dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant: 'not-enough-living-skulls',
                    }),
                );
            } else {
                dispatch(
                    globalOp.handleOpenSnackbar({
                        open: true,
                        variant: 'error',
                        message: 'something.went.wrong',
                    }),
                );
            }
        }
    };
};

const updateFeaturedOffersCache = () => {
    const { setLoading, setFeaturedOffersItems } = featuredOffersSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const { data } = await fetch({
                endPoint: `/cache/featured-offers`,
            });

            dispatch(setFeaturedOffersItems(data));
        } catch (error) {
            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'something.went.wrong',
                }),
            );
        }

        dispatch(setLoading(false));
    };
};

export const featuredOffersOp = {
    getFeaturedOffersItems,
    getXsollaToken,
    getExclusiveOffers,
    freePurchase,
    updateFeaturedOffersCache,
};

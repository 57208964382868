import React from 'react';

import { useDispatch } from 'react-redux';
import { globalOp } from 'store/global';

import { useTheme } from '@mui/material';
import { Box, Typography, Button } from 'style-guide';
import ModalIcon from './ModalIcon';

import { useTranslation } from 'hooks';
import { addLanguageCodeToLink } from 'helpers/utils';

import livingSkullsIcon from 'assets/images/living-skulls-modal-icon.svg';

const NotEnoughLivingSkullsModal = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const {
        palette: { text },
    } = theme;

    const handleCloseModal = () => dispatch(globalOp.handleCloseModal());

    return (
        <Box display="flex" flexDirection="column" alignItems="center" maxWidth={320}>
            <ModalIcon icon={livingSkullsIcon} />
            <Typography fontWeight="bold" variant="h4" color={text[300]} textAlign="center" mb={2}>
                {t('not.enough.living.skulls')}
            </Typography>
            <Typography color={text[600]} mb={10} textAlign="center">
                {t('earn.living.skulls.in.the.featured.offers')}
            </Typography>
            <Box width={140}>
                <Button
                    size="small"
                    onClick={handleCloseModal}
                    href={addLanguageCodeToLink('/featured-offers')}
                >
                    {t('go.to.shop')}
                </Button>
            </Box>
        </Box>
    );
};

export default NotEnoughLivingSkullsModal;

import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox as MUICheckbox, useTheme } from '@mui/material';

const Checkbox = ({ color, id, name, checked, onChange }) => {
    const theme = useTheme();

    return (
        <MUICheckbox
            name={name}
            id={id}
            onChange={onChange}
            checked={checked}
            color={color}
            sx={{ color: theme.palette.grey[700] }}
        />
    );
};

Checkbox.propTypes = {
    color: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
};

Checkbox.defaultProps = {
    color: 'secondary',
    checked: false,
    name: '',
    onChange: () => {},
};

export default Checkbox;

import { useEffect, useState } from 'react';

export const useOnline = () => {
    const [online, setOnline] = useState(true);
    const handleConnectionChange = () => setOnline(navigator.onLine);

    useEffect(() => {
        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);

        return () => {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        };
    }, []);

    return online;
};

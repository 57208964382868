import { styled } from '@mui/material/styles';
import { Box } from 'style-guide';

export const StyledNotFoundPage = styled(Box)(() => ({
    textAlign: 'center',
    width: 'calc(100% + 40px)',
    transform: 'translateX(-20px)',
    '& .icon-arrow-left-s-line': {
        transform: 'rotate(180deg)',
    },
}));

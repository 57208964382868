import { createSlice } from '@reduxjs/toolkit';

import { initialLivingSkullsState } from './initialState';

export const livingSkullsSlice = createSlice({
    name: 'livingSkulls',
    initialState: initialLivingSkullsState(),
    reducers: {
        setLoading(state, action) {
            state.isLivingSkullsLoading = action.payload;
        },
        setLivingSkullsItems(state, action) {
            const livingSkulls = {};
            for (const key in action.payload) {
                livingSkulls[key] = [
                    {
                        ShopTabName: action.payload[key][0].ShopTabName,
                        ShopItems: action.payload[key][0].ShopItems.map((item) => ({
                            ...item,
                            IsLivingSkull: true,
                        })),
                    },
                ];
            }
            state.livingSkullsItems = livingSkulls;
        },
    },
});

import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Button as MUIButton, CircularProgress } from '@mui/material';
import { Box, Typography, Icon } from 'style-guide';

import buttonEffect from 'assets/images/button-effect-black.svg';

const TYPES = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SOLID: 'solid',
    AQUA: 'aqua',
    FACEBOOK: 'facebook',
    SCOPELY: 'scopely',
    SIGNIN: 'signin',
};

const TYPE_STYLES = {
    [TYPES.PRIMARY]: { buttonType: 'primary' },
    [TYPES.SECONDARY]: { buttonType: 'secondary' },
    [TYPES.SOLID]: { buttonType: 'solid' },
    [TYPES.AQUA]: { buttonType: 'aqua' },
    [TYPES.FACEBOOK]: { buttonType: 'facebook' },
    [TYPES.SCOPELY]: { buttonType: 'scopely' },
    [TYPES.SIGNIN]: { buttonType: 'signin' },
};

const SIZES = {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
};

const SIZE_STYLES = {
    [SIZES.LARGE]: {
        text: { variant: 'p16' },
        size: { height: 52 },
        loading: { pr: 4, maxHeight: '24px' },
    },
    [SIZES.MEDIUM]: {
        text: { variant: 'p14' },
        size: { height: 44 },
        loading: { pr: 3, maxHeight: '20px' },
    },
    [SIZES.SMALL]: {
        text: { variant: 'p12' },
        size: { height: 32, textTransform: 'capitalize' },
        loading: { pr: 2, maxHeight: '16px' },
    },
};

const StyledButton = styled(MUIButton)(({ theme, styles, background, button_type }) => ({
    width: '100%',
    justifyContent: 'center',
    color: theme.palette[button_type].color,
    boxShadow: 'none',
    backgroundImage: background,
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    borderRadius: theme.palette[button_type].borderRadius,
    flexWrap: 'nowrap',
    border: `1px solid ${theme.palette[button_type].mainBorder}`,
    backgroundColor: theme.palette[button_type].main,
    ...styles,
    '&:hover': {
        boxShadow: 'none',
        border: `1px solid ${theme.palette[button_type].hoverBorder}`,
        backgroundColor: theme.palette[button_type].hover,
    },
    '&:disabled': {
        color: theme.palette.text[300],
        backgroundColor: theme.palette[button_type].main,
        opacity: 0.5,
    },
}));

const Button = ({
    type,
    size,
    disabled,
    icon,
    isLoading,
    htmlType,
    onClick,
    children,
    href,
    styles,
    hasBackground,
}) => {
    let renderIcon = null;
    if (isLoading) {
        renderIcon = (
            <Box sx={SIZE_STYLES[size].loading}>
                <CircularProgress color="inherit" size={SIZE_STYLES[size].loading.maxHeight} />
            </Box>
        );
    }
    if (icon && !isLoading) {
        renderIcon = (
            <Box
                sx={{
                    ...SIZE_STYLES[size].loading,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Icon size={parseInt(SIZE_STYLES[size].loading.maxHeight)} name={icon} />
            </Box>
        );
    }

    return (
        <StyledButton
            type={htmlType}
            variant={TYPE_STYLES[type].variant}
            button_type={TYPE_STYLES[type].buttonType}
            disabled={disabled}
            onClick={isLoading ? () => {} : onClick}
            sx={SIZE_STYLES[size].size}
            href={href}
            styles={styles}
            background={hasBackground ? `url(${buttonEffect})` : 'none'}
        >
            {renderIcon}
            <Typography
                span
                noWrap
                overflow="unset"
                fontWeight="bold"
                fontFamily="helvetica"
                {...SIZE_STYLES[size].text}
            >
                {children}
            </Typography>
        </StyledButton>
    );
};

Button.propTypes = {
    children: PropTypes.string.isRequired,
    icon: PropTypes.node,
    type: PropTypes.oneOf(Object.values(TYPES)),
    size: PropTypes.oneOf(Object.values(SIZES)),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    htmlType: PropTypes.string,
    href: PropTypes.string,
    styles: PropTypes.object,
    hasBackground: PropTypes.bool,
};

Button.defaultProps = {
    type: TYPES.PRIMARY,
    size: SIZES.MEDIUM,
    icon: null,
    isLoading: false,
    onClick: null,
    disabled: false,
    htmlType: 'button',
    styles: {},
    hasBackground: true,
};

export default Button;

import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AppLogo from '../../components/AppLogo';
import { StyledLeftSidebar } from './LeftSidebar.styled';
import LeftSidebarNav from './LeftSidebarNav';
import { getNavigationList } from './helpers';
import LeftSidebarFooter from './LeftSidebarFooter';

import { globalSel } from 'store/global';

const LeftSidebar = ({ openLeftSidebar, setOpenLeftSidebar }) => {
    const currentUser = useSelector(globalSel.currentUserSelector);

    const listNavigation = useMemo(() => {
        return getNavigationList(currentUser);
    }, [currentUser]);

    return (
        <StyledLeftSidebar className={openLeftSidebar ? 'show' : ''}>
            <AppLogo isLeftSide setOpenLeftSidebar={setOpenLeftSidebar} />
            <LeftSidebarNav
                navigationList={listNavigation}
                setOpenLeftSidebar={setOpenLeftSidebar}
            />
            <LeftSidebarFooter />
        </StyledLeftSidebar>
    );
};

LeftSidebar.propTypes = {
    openLeftSidebar: PropTypes.bool.isRequired,
    setOpenLeftSidebar: PropTypes.func.isRequired,
};

export default memo(LeftSidebar);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';
import { Icon, Box } from 'style-guide';

import { useSelector } from 'react-redux';
import { globalSel } from 'store/global';
import { Link } from 'react-router-dom';

import { addLanguageCodeToLink } from 'helpers/utils';

const Avatar = ({ image, handleClick }) => {
    const theme = useTheme();
    const [imageNotFoundId, setImageNotFoundId] = useState(false);
    const currentUser = useSelector(globalSel.currentUserSelector);

    return (
        <Box
            component={Link}
            to={addLanguageCodeToLink(
                `/players/profile/${currentUser?.FriendCode}/realm/${currentUser?.CurrentRealm}`,
            )}
        >
            {imageNotFoundId ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={32}
                    height={32}
                    ml={3}
                    borderRadius="50%"
                    onClick={handleClick}
                >
                    <Icon size={18} name="user" />
                </Box>
            ) : (
                <Box
                    component="img"
                    onError={() => setImageNotFoundId(true)}
                    onClick={handleClick}
                    src={image}
                    alt="avatar"
                    sx={{
                        border: `1px solid ${theme.palette.text[400]}`,
                        width: 32,
                        height: 32,
                        display: 'inline-block',
                        ml: 3,
                        borderRadius: 50,
                    }}
                />
            )}
        </Box>
    );
};

Avatar.defaultProps = {
    handleClick: () => {},
};

Avatar.propTypes = {
    image: PropTypes.string,
    handleClick: PropTypes.func,
};

export default Avatar;

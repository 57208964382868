import React, { useEffect } from 'react';
import platform from 'platform';

import { useDispatch, useSelector } from 'react-redux';
import { globalSel } from 'store/global';
import { translationsOp, translationsSel } from 'store/translations';

import { ThemeProvider } from '@mui/material';
import theme from 'theme/theme';
import GlobalStyles from 'theme/global.styled';
import SnackBar from 'views/components/SnackBar';
import Modal from 'views/components/Modal';
import BrowserSupport from 'views/pages/BrowserSupport';
import Router from './router';
import 'theme/styles.scss';

import voluum, { tracker } from 'voluum';

import { useOnline } from 'hooks';
import { getStorageItem } from 'helpers/localStorage';

// Titan analytics send event  function
const sendAppOpenEvent = (is_warm_start) => {
    tracker.track('sys.app_open', {
        is_warm_start,
        tenant: '',
        alliance_id: '',
        alliance_name: '',
        league_level: '',
        player_level: '',
        player_name: '',
        user_id: getStorageItem('user_Id'),
    });
};

const App = () => {
    const authenticated = useSelector(globalSel.isAuthSelector);
    const online = useOnline();
    const dispatch = useDispatch();
    const { getTranslations } = translationsOp;
    const selectedLanguage = useSelector(translationsSel.selectedLanguageSelector);

    useEffect(() => {
        // Xsolla Paystation Setup
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = '//cdn.xsolla.net/embed/paystation/1.0.7/widget.min.js';
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(script);
        // Voluum analytics send event function
        voluum();
        sendAppOpenEvent(false);
    }, []);

    useEffect(() => {
        dispatch(getTranslations('common'));
    }, [dispatch, getTranslations, selectedLanguage]);

    return (
        <ThemeProvider theme={theme}>
            {platform.description.includes('Opera') ? (
                <BrowserSupport />
            ) : (
                <>
                    <Router />
                    <SnackBar />
                    <Modal />
                </>
            )}
            <GlobalStyles authenticated={authenticated} online={online} />
        </ThemeProvider>
    );
};

export default App;

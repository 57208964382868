import { createSlice } from '@reduxjs/toolkit';

import { initialFeaturedOffersState } from './initialState';

export const featuredOffersSlice = createSlice({
    name: 'featuredOffers',
    initialState: initialFeaturedOffersState(),
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setExclusiveOffersLoading(state, action) {
            state.exclusiveOffersLoading = action.payload;
        },
        setExclusiveOffers(state, action) {
            const data = action.payload;
            state.exclusiveOffers = data.exclusiveShopTab[0].ShopItems;
        },
        setFeaturedOffersItems(state, action) {
            const { specialShopTab, offerShopTab, coinShopTab, customShopTab, banners } =
                action.payload;
            state.featuredOffers = {
                specialOffers: specialShopTab,
                offers: offerShopTab,
                coinOffers: coinShopTab,
                customOffers: customShopTab,
                banners,
            };
        },
        setXsollaToken(state, action) {
            state.xsollaToken = action.payload;
        },
    },
});

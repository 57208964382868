// global
export const API_ENV = process.env.REACT_APP_ENVIRONMENT;
export const API_URL = `${process.env.REACT_APP_API_URL}/v1`;
export const APP_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

// amazon
export const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
export const IMAGES_S3_BUCKET_URL = process.env.REACT_APP_IMAGES_S3_BUCKET_URL;
export const IMAGES_URL = `${API_URL}/assets/images`;

// voluum
export const VOLUUM_API_KEY = process.env.REACT_APP_VOLUUM_API_KEY;
export const VOLUUM_CAMPAIGN_ID = process.env.REACT_APP_VOLUUM_CAMPAIGN_ID;
export const VOLUUM_OFFER_ID = process.env.REACT_APP_VOLUUM_OFFER_ID;

// facebook
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;

// scopely
export const SCOPELY_LOGIN_ENDPOINT = process.env.REACT_APP_SCOPELY_LOGIN_ENDPOINT;
export const SCOPELY_LOGIN_CLIENT_ID = process.env.REACT_APP_SCOPELY_LOGIN_CLIENT_ID;
export const SCOPELY_URLS = {
    PRIVACY_POLICY: `https://scopely.com/privacy/`,
    TERMS: `https://scopely.com/tos/`,
};

// bugsnag
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY;

// DD_RUM
export const DATADOGROOM_APPLICATION_ID = process.env.REACT_APP_DATADOGROOM_APPLICATION_ID;
export const DATADOGROOM_CLIENT_TOKEN = process.env.REACT_APP_DATADOGROOM_CLIENT_TOKEN;
export const DATADOGROOM_SERVICE = process.env.REACT_APP_DATADOGROOM_SERVICE;

// mailchimp
export const MAILCHIMP_API = {
    MAILCHIMP_URL: `https://twdrts.us3.list-manage.com/subscribe/post?u=aa6004915000d58f67c4bebe8&amp;id=84e87305a0`,
    CHECKBOX_ID: 'gdpr_44405',
    CHECKBOX_NAME: 'gdpr[44405]',
};

export const TRANSLATION_QUERIES = {
    dashboard: '%7B%22page%22%3A+%7B%22contains%22%3A+%22dashboard%22+%7D+%7D',
    featuredOffers: '%7B%22page%22%3A+%7B%22contains%22%3A+%22featured-offers%22+%7D+%7D',
    event: '%7B%22page%22%3A+%7B%22contains%22%3A+%22event%22+%7D+%7D',
    livingSkulls: '%7B%22page%22%3A+%7B%22contains%22%3A+%22living-skulls%22+%7D+%7D',
    myProfile: '%7B%22page%22%3A+%7B%22contains%22%3A+%22my-profile%22+%7D+%7D',
    myFaction: '%7B%22page%22%3A+%7B%22contains%22%3A+%22my-faction%22+%7D+%7D',
    common: '%7B%22page%22%3A+%7B%22contains%22%3A+%22common%22+%7D+%7D',
    blog: '%7B%22page%22%3A+%7B%22contains%22%3A+%22blog%22+%7D+%7D',
};
export const TRANSLATIONS_URI = `${process.env.REACT_APP_TRANSLATIONS_URI}/${process.env.REACT_APP_TRANSLATIONS_VIEW_ID}`;
export const TRANSLATIONS_API_KEY = `${process.env.REACT_APP_TRANSLATIONS_API_KEY}`;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Typography as MUITypography } from '@mui/material';

const VARIANTS = {
    HEADLINE: 'headline',
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    P18: 'p18',
    P16: 'p16',
    P14: 'p14',
    P12: 'p12',
    P10: 'p10',
};

const VARIANT_TYPES = {
    [VARIANTS.HEADLINE]: 'h1',
    [VARIANTS.H1]: 'h2',
    [VARIANTS.H2]: 'h3',
    [VARIANTS.H3]: 'h4',
    [VARIANTS.H4]: 'h5',
    [VARIANTS.P18]: 'p',
    [VARIANTS.P16]: 'p',
    [VARIANTS.P14]: 'p',
    [VARIANTS.P12]: 'p',
    [VARIANTS.P10]: 'p',
};

const FONT_FAMILIES = {
    EDO: 'edo',
    HELVETICA: 'helvetica',
};

const WEIGHTS = {
    REGULAR: 'regular',
    BOLD: 'bold',
};

const WEIGHT_TYPES = {
    [WEIGHTS.REGULAR]: 400,
    [WEIGHTS.BOLD]: 700,
};

const Typography = forwardRef(
    (
        { variant, color, fontWeight, fontFamily, noWrap, span, uppercase, children, ...props },
        ref,
    ) => {
        const component = span ? 'span' : VARIANT_TYPES[variant];

        return (
            <MUITypography
                ref={ref}
                {...props}
                variant={variant}
                color={color}
                fontWeight={WEIGHT_TYPES[fontWeight]}
                fontFamily={fontFamily + ', sans-serif'}
                noWrap={noWrap}
                component={component}
                textTransform={uppercase ? 'uppercase' : null}
            >
                {children}
            </MUITypography>
        );
    },
);

Typography.displayName = 'Typography';

Typography.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    fontFamily: PropTypes.oneOf(Object.values(FONT_FAMILIES)),
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    fontWeight: PropTypes.oneOf(Object.values(WEIGHTS)),
    color: PropTypes.string,
    uppercase: PropTypes.bool,
    noWrap: PropTypes.bool,
    span: PropTypes.bool,
};

Typography.defaultProps = {
    variant: VARIANTS.P16,
    fontFamily: FONT_FAMILIES.HELVETICA,
    fontWeight: WEIGHTS.REGULAR,
    uppercase: false,
    noWrap: false,
    span: false,
    color: 'inherit',
};

export default Typography;

import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';
import { Box } from 'style-guide';

import { hexToRgba } from 'helpers/hexToRgba';

const ModalIcon = ({ icon }) => {
    const theme = useTheme();
    const {
        palette: { text },
    } = theme;

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={7}
            width={100}
            height={100}
            borderRadius="50%"
            backgroundColor={hexToRgba(text[400], 0.25)}
            sx={{ filter: 'drop-shadow(0 0 50px rgba(250, 247, 230, 0.3))' }}
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={76}
                height={76}
                borderRadius="50%"
                backgroundColor={text[400]}
                border={`1px solid ${text[300]}`}
            >
                <Box component="img" src={icon} alt="login-icon" />
            </Box>
        </Box>
    );
};

ModalIcon.propTypes = {
    icon: PropTypes.string,
};

export default ModalIcon;

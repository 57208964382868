import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'hooks';

import { globalOp, globalSel } from 'store/global';
import { FB_APP_ID } from 'constants/api.constants';

import { Button, Box, Tooltip, Icon } from 'style-guide';
import { ClickAwayListener, Hidden, useTheme } from '@mui/material';
import AppLogo from 'views/components/AppLogo';
import PlayerProfileInfo from './PlayerProfileInfo';
import LoginWithFacebook from 'views/components/LoginWithFacebook';
import LoginWithScopely from 'views/components/LoginWithScopely';
import { getStorageItem } from 'helpers/localStorage';
import PlayerProfileInfoSkeleton from './PlayerProfileInfo/Skeletons';
import { StyledHeader } from './Header.styled';

const Header = ({ setOpenLeftSidebar }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const headerRef = useRef();
    const theme = useTheme();
    const { handleFirstLogin } = globalOp;
    const authenticated = useSelector(globalSel.isAuthSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const isLoading = useSelector(globalSel.isLoadingSelector);
    const firstLogin = useSelector(globalSel.firstLoginSelector);
    const [infoDropdownOpen, setInfoDropdownOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const loginClickToggle = () => setOpen((prevState) => !prevState);
    const accessToken = getStorageItem('accessToken');
    const handleTooltipClose = () => setOpen(false);

    useEffect(() => {
        if (firstLogin) {
            setInfoDropdownOpen(false);
            dispatch(handleFirstLogin(false));
        }
    }, [firstLogin, setInfoDropdownOpen, dispatch, handleFirstLogin]);

    const changeBackground = () => {
        if (headerRef.current) {
            if (window.scrollY) {
                headerRef.current.style.backgroundColor = '#000';
            } else {
                headerRef.current.style.backgroundColor = 'transparent';
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeBackground);

        return () => window.removeEventListener('scroll', changeBackground);
    });

    return (
        <StyledHeader
            sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                left: [0, 0, 240],
                px: [4, 8],
                zIndex: 1100,
                height: 70,
                borderBottom: `1px solid ${theme.palette.black[400]}`,
            }}
            ref={headerRef}
        >
            <Box display="flex" alignItems="center" justifyContent="flex-end" height={1}>
                <Hidden mdUp>
                    <Box sx={{ mr: 5, cursor: 'pointer' }} onClick={() => setOpenLeftSidebar(true)}>
                        <Icon name="menu-hamburger" size={24} />
                    </Box>

                    <Box flexGrow={1}>
                        <AppLogo />
                    </Box>
                </Hidden>

                <Box display="flex" alignItems="center" pl={3}>
                    {accessToken && !authenticated ? (
                        <PlayerProfileInfoSkeleton />
                    ) : authenticated && currentUser ? (
                        <PlayerProfileInfo
                            infoDropdownOpen={infoDropdownOpen}
                            setInfoDropdownOpen={setInfoDropdownOpen}
                        />
                    ) : (
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <div>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    className="tooltip-wrapper"
                                    title={
                                        <>
                                            <Box mb={2} width={[200, 228]}>
                                                <LoginWithScopely isLoading={isLoading} />
                                            </Box>
                                            <Box width={[200, 228]}>
                                                <LoginWithFacebook
                                                    isLoading={isLoading}
                                                    appId={FB_APP_ID}
                                                    disableMobileRedirect
                                                />
                                            </Box>
                                        </>
                                    }
                                >
                                    <Box
                                        sx={[
                                            {
                                                '& button': {
                                                    width: [200, 244],
                                                    height: 44,
                                                    [theme.breakpoints.down('sm')]: {
                                                        width: 130,
                                                    },
                                                },
                                            },
                                        ]}
                                    >
                                        <Button
                                            onClick={loginClickToggle}
                                            size="medium"
                                            type="signin"
                                            hasBackground={false}
                                            styles={{
                                                textTransform: 'initial',
                                            }}
                                        >
                                            {t('sign.in')}
                                        </Button>
                                    </Box>
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                    )}
                </Box>
            </Box>
        </StyledHeader>
    );
};

Header.propTypes = {
    setOpenLeftSidebar: PropTypes.func,
};
export default memo(Header);

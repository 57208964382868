import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';
import { Box, Typography } from 'style-guide';
import ModalIcon from './ModalIcon';

import { useTranslation } from 'hooks';

import payment_reward_success from 'assets/images/payment_reward_success.svg';

const SuccessfulModal = ({ message }) => {
    const [t] = useTranslation();
    const theme = useTheme();
    const {
        palette: { text },
    } = theme;

    return (
        <Box display="flex" flexDirection="column" alignItems="center" maxWidth={320}>
            <ModalIcon icon={payment_reward_success} />
            {message ? (
                <Typography
                    fontWeight="bold"
                    color={text[300]}
                    textAlign="center"
                    mb={2}
                    variant="h4"
                >
                    {t(message)}!
                </Typography>
            ) : null}
            <img src="https://sboffers.swagbucks.com/GL9Wa" alt="" width="1" height="1" />
            <Typography
                fontWeight={message ? 'regular' : 'bold'}
                variant={message ? 'p16' : 'h4'}
                color={message ? text[600] : text[300]}
                textAlign="center"
            >
                {t('rewards.sent.inbox')}
            </Typography>
        </Box>
    );
};

SuccessfulModal.propTypes = {
    message: PropTypes.string,
};

export default SuccessfulModal;

import React from 'react';
import { PropTypes } from 'prop-types';

import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const StyledInput = styled(TextField)(({ theme }) => ({
    borderRadius: '6px',
    backgroundColor: theme.palette.black[500],

    '& .MuiCheckbox-root': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        '& hover': {
            backgroundColor: 'none',
        },
    },
    '& input': {
        padding: '12px',
        fontSize: '12px',
        color: theme.palette.text[300],
    },
    '& fieldset': {
        border: 'none',
    },
    '&:hover fieldset': {
        border: 'none',
    },
    '&.Mui-focused fieldset': {
        border: 'none',
    },
}));

const Input = ({ placeholder, type, name, id, value, autoComplete, onChange }) => {
    return (
        <StyledInput
            name={name}
            type={type}
            id={id}
            value={value}
            autoComplete={autoComplete}
            placeholder={placeholder}
            fullWidth
            onChange={onChange}
        />
    );
};

Input.propTypes = {
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    autoComplete: PropTypes.string,
    onChange: PropTypes.func,
};

Input.defaultProps = {
    type: 'text',
    autoComplete: 'on',
    onChange: () => {},
};

export default Input;

import { fetch } from 'services';

import { globalOp } from 'store/global';

import { factionSlice } from './factionSlice';

const getFaction = (guildId, realmId) => {
    const { setLoading, setFaction } = factionSlice.actions;
    return async (dispatch) => {
        if (!Number(guildId) && !Number(realmId)) return;
        dispatch(setLoading(true));

        try {
            const { data: faction } = await fetch({
                endPoint: `/guilds/${guildId}/realm/${realmId}`,
            });
            dispatch(setFaction(faction));
        } catch (error) {
            dispatch(setFaction(null));
            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'something.went.wrong',
                }),
            );
        }
        dispatch(setLoading(false));
    };
};

const getFactionCheckIn = (factionId) => {
    const { setIsFactionCheckInLoading, setFactionCheckIn } = factionSlice.actions;

    return async (dispatch) => {
        dispatch(setIsFactionCheckInLoading(true));
        try {
            const endPoint = factionId
                ? `/faction-check-in?factionId=${factionId}`
                : `/faction-check-in`;
            const { data: factionCheckIn } = await fetch({
                endPoint,
            });

            dispatch(setFactionCheckIn(factionCheckIn));
        } catch (error) {
            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'something.went.wrong',
                }),
            );
        }
        dispatch(setIsFactionCheckInLoading(false));
    };
};

const checkInAndClaim = ({ factionId }) => {
    const { setLoading } = factionSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            await fetch({
                endPoint: `/faction-check-in/claim`,
                method: 'POST',
                body: { factionId },
            });
            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: 'reward-success',
                    message: 'reward.claimed',
                }),
            );
            dispatch(globalOp.authMe());
        } catch (error) {
            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'something.went.wrong',
                }),
            );
        }
        dispatch(setLoading(false));
    };
};

export const factionOp = {
    getFaction,
    getFactionCheckIn,
    checkInAndClaim,
};

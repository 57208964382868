import React from 'react';
import PropTypes from 'prop-types';

import { LinearProgress as MUILinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import theme from 'theme/theme';

const SIZES = {
    LARGE: 'large',
    SMALL: 'small',
};

const SIZE_STYLES = {
    [SIZES.LARGE]: {
        height: '20px',
        borderRadius: '10px',
        border: '2px solid rgba(255, 255, 255, 0.2)',
    },
    [SIZES.SMALL]: {
        height: '6px',
        borderRadius: '10px',
        border: null,
    },
};

const TYPES = {
    FIRE: 'fire',
    GREY: 'grey',
    BLUE: 'blue',
};

const TYPE_STYLES = {
    [TYPES.FIRE]: theme.palette.gradient.fire,
    [TYPES.BLUE]: theme.palette.gradient.blue,
    [TYPES.GREY]: theme.palette.grey[700],
};

const StyledLinearProgress = styled(MUILinearProgress)(({ theme, size, type, value }) => ({
    borderRadius: SIZE_STYLES[size].borderRadius,
    '&.MuiLinearProgress-root': {
        height: SIZE_STYLES[size].height,
        backgroundColor: theme.palette.black[300],
    },
    '& .MuiLinearProgress-bar': {
        backgroundColor: 'unset',
        width: `${value}%`,
        transform: 'unset !important',
        border: value < 2 ? null : SIZE_STYLES[size].border,
        borderRadius: SIZE_STYLES[size].borderRadius,
        backgroundImage: TYPE_STYLES[type],
        backgroundRepeatX: 'no-repeat',
        backgroundSize: '110%',
        backgroundPosition: 'center',
    },
}));

const LinearProgress = ({ value, size, type }) => {
    return (
        <StyledLinearProgress
            variant="determinate"
            value={value > 100 ? 100 : value}
            size={size}
            type={type}
        />
    );
};

LinearProgress.propTypes = {
    value: PropTypes.number.isRequired,
    type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
    size: PropTypes.oneOf(Object.values(SIZES)),
};

LinearProgress.defaultProps = {
    size: SIZES.LARGE,
};

export default LinearProgress;

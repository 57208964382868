import React from 'react';

import { useDispatch } from 'react-redux';
import { globalOp } from 'store/global';

import { useTheme } from '@mui/material';
import { Box, Icon } from 'style-guide';

import modalCloseIconBg from 'assets/images/modal-close-icon-bg.png';

const ModalCloseIcon = () => {
    const theme = useTheme();
    const {
        palette: { grey },
    } = theme;

    const dispatch = useDispatch();
    const handleCloseModal = () => dispatch(globalOp.handleCloseModal());

    return (
        <Box
            onClick={handleCloseModal}
            sx={{
                position: 'absolute',
                top: -16,
                right: -16,
                width: 32,
                height: 32,
                borderRadius: '50%',
                border: `1px solid ${grey[300]}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: grey[600],
                backgroundImage: `url(${modalCloseIconBg})`,
                cursor: 'pointer',
                transition: 'opacity 0.5s',
                '&:hover': {
                    opacity: 0.8,
                },
            }}
        >
            <Icon name="close" size={12} color={theme.palette.black[300]} />
        </Box>
    );
};
export default ModalCloseIcon;

// BLACK
const black800 = '#000000';
const black700 = '#191919';
const black600 = '#212121';
const black500 = '#262626';
const black400 = '#333333'; // solid
const black300 = '#404040';

// GREY
const grey900 = '#979390';
const grey800 = '#595959';
const grey700 = '#737373';
const grey600 = '#8C8C8C';
const grey500 = '#A7A7A7';
const grey400 = '#BFBFBF';
const grey300 = '#D9D9D9';

// RED
const red800 = '#7A201F';
const red700 = '#A32B29';
const red600 = '#DA302E'; // primary
const red500 = '#CD3D3B';
const red400 = '#D86665';
const red300 = '#E28E8D';

// YELLOW
const yellow800 = '#CC8600';
const yellow700 = '#FFA800'; // secondary
const yellow600 = '#FFC24D';

// BLUE
const blue800 = '#133A67';
const blue700 = '#236BBE';
const blue600 = '#5394DF';
const blue500 = '#0C2AA9';
const blue400 = '#0F35D6';
const blue300 = '#1588EA';

// GRADIENTS
const fireGradient = `linear-gradient(90deg, ${red500} 0%, ${yellow700} 100%)`;
const blackGradient = `linear-gradient(90deg, ${black300} 0%, ${black600} 100%)`;
const greyGradient = `linear-gradient(180deg, ${grey800} 0%, ${black400} 100%)`;
const blueGradient = `linear-gradient(90deg, ${blue800} 0%, ${blue600} 100%)`;
const skeletonGradient = `linear-gradient(90deg, ${black500}, ${black400}, transparent)`;

// TEXT
const text800 = '#7D7C73';
const text700 = '#96948A';
const text600 = '#AFADA1';
const text500 = '#C8C6B8';
const text400 = '#E1DECF';
const text300 = '#FAF7E6';

export const palette = {
    primary: {
        main: red600,
        mainBorder: red800,
        hover: red500,
        hoverBorder: red400,
        color: text300,
        borderRadius: '6px',
    },
    secondary: {
        main: yellow700,
        mainBorder: yellow800,
        hover: yellow600,
        hoverBorder: yellow700,
        color: text300,
        borderRadius: '6px',
    },
    solid: {
        main: grey800,
        mainBorder: grey700,
        hover: black300,
        hoverBorder: grey800,
        color: text300,
        borderRadius: '6px',
    },
    aqua: {
        main: blue700,
        mainBorder: blue800,
        hover: blue700,
        hoverBorder: blue600,
        color: text300,
        borderRadius: '6px',
    },
    facebook: {
        main: '#fff',
        mainBorder: '#fff',
        hover: text300,
        hoverBorder: text300,
        color: blue300,
        borderRadius: 0,
    },
    scopely: {
        main: '#fff',
        mainBorder: '#fff',
        hover: text300,
        hoverBorder: text300,
        color: blue500,
        borderRadius: 0,
    },
    signin: {
        main: blue500,
        mainBorder: blue500,
        hover: blue400,
        hoverBorder: blue400,
        color: '#fff',
        borderRadius: 0,
    },
    red: {
        800: red800,
        700: red700,
        600: red600,
        500: red500,
        400: red400,
        300: red300,
    },
    yellow: {
        800: yellow800,
        700: yellow700,
        600: yellow600,
    },
    black: {
        800: black800,
        700: black700,
        600: black600,
        500: black500,
        400: black400,
        300: black300,
    },
    blue: {
        800: blue800,
        700: blue700,
        600: blue600,
    },
    grey: {
        900: grey900,
        800: grey800,
        700: grey700,
        600: grey600,
        500: grey500,
        400: grey400,
        300: grey300,
    },
    gradient: {
        fire: fireGradient,
        black: blackGradient,
        grey: greyGradient,
        blue: blueGradient,
        skeleton: skeletonGradient,
    },
    text: {
        800: text800,
        700: text700,
        600: text600,
        500: text500,
        400: text400,
        300: text300,
    },
};

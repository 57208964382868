import React, { useEffect } from 'react';

import { FB_APP_ID } from 'constants/api.constants';

import { useDispatch, useSelector } from 'react-redux';
import { globalOp, globalSel } from 'store/global';

import { useTheme } from '@mui/material';
import { Box, Typography } from 'style-guide';
import ModalIcon from './ModalIcon';
import LoginWithFacebook from 'views/components/LoginWithFacebook';
import LoginWithScopely from 'views/components/LoginWithScopely';

import { useTranslation } from 'hooks';
import { getStorageItem, removeStorageItem } from 'helpers/localStorage';

import modal_facebook from 'assets/images/modal-facebook.svg';

const LoginModal = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const {
        palette: { text },
    } = theme;

    const dispatch = useDispatch();
    const isLoading = useSelector(globalSel.isLoadingSelector);
    const authenticated = useSelector(globalSel.isAuthSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);

    useEffect(() => {
        const pageToRedirect = getStorageItem('loggedOutRedirectUrl');

        if (authenticated && currentUser && pageToRedirect && window) {
            window.location = pageToRedirect;
            removeStorageItem('loggedOutRedirectUrl');
            dispatch(globalOp.handleCloseModal());
        }
    }, [authenticated, currentUser, dispatch]);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" maxWidth={320}>
            <ModalIcon icon={modal_facebook} />
            <Typography fontWeight="bold" color={text[300]} textAlign="center" mb={10} variant="h4">
                {t('login.to.continue')}!
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <LoginWithScopely isLoading={isLoading} />
                <LoginWithFacebook isLoading={isLoading} appId={FB_APP_ID} disableMobileRedirect />
            </Box>
        </Box>
    );
};

export default LoginModal;

import { styled } from '@mui/material/styles';
import { Box } from 'style-guide';

import maintenance_effect from 'assets/images/maintenance-effect.png';
import maintenance_bg from 'assets/images/maintenance-bg.png';
import center_bg from 'assets/images/mainteance_cent_bg.png';
import center_circle from 'assets/images/mainteance_cent_circle.png';

export const StyledMaintenance = styled(Box)(({ theme }) => ({
    overflow: 'hidden',
    '.WP-MaintenanceComponent-bg': {
        '&:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 0,
            backgroundImage: `url(${maintenance_effect})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            mixBlendMode: 'multiply',
        },

        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1,
            backgroundImage: `url(${maintenance_bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            backgroundPosition: 'center',
            [theme.breakpoints.down('md')]: {
                backgroundSize: '110%',
            },
            [theme.breakpoints.down('sm')]: {
                backgroundSize: '120%',
            },
        },

        [theme.breakpoints.down('sm')]: {
            '&:before': {
                background: 'none',
            },
        },
    },
    '.WP-MaintenanceComponent-center-bg': {
        minWidth: 844,
        height: 334,
        backgroundImage: `url(${center_bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginBottom: '180px',

        [theme.breakpoints.down('xs')]: {
            minWidth: '100%',
            marginBottom: '140px',
        },
    },

    '.WP-MaintenanceComponent-center-circle': {
        width: 200,
        height: 200,
        backgroundImage: `url(${center_circle})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',

        [theme.breakpoints.down('sm')]: {
            width: 160,
            height: 160,
        },
    },
}));

import { createSelector } from 'reselect';

const globalSelector = (state) => state.global;

const isAuthSelector = createSelector([globalSelector], (global) => global.authenticated);
const currentUserSelector = createSelector([globalSelector], (global) => global.currentUser);
const snackbarSelector = createSelector([globalSelector], (global) => global.snackbar);
const themeSelector = createSelector([globalSelector], (global) => global.theme);
const modalSelector = createSelector([globalSelector], (global) => global.modal);
const isLoadingSelector = createSelector([globalSelector], (global) => global.isLoading);
const maintenanceModeSelector = createSelector([globalSelector], (global) => global.maintenance);
const globalConfigSelector = createSelector([globalSelector], (global) => global.globalConfig);
const firstLoginSelector = createSelector([globalSelector], (global) => global.firstLogin);
const isRealmLoadingSelector = createSelector([globalSelector], (global) => global.isRealmLoading);
const isLeaderboardsLoadingSelector = createSelector(
    [globalSelector],
    (global) => global.isLeaderboardsLoading,
);
const globalLeaderboardsSelector = createSelector(
    [globalSelector],
    (global) => global.globalLeaderboards,
);
const dashboardBannerSelector = createSelector(
    [globalSelector],
    (global) => global.dashboardBanner,
);

export const globalSel = {
    isAuthSelector,
    currentUserSelector,
    themeSelector,
    snackbarSelector,
    modalSelector,
    isLoadingSelector,
    globalLeaderboardsSelector,
    isLeaderboardsLoadingSelector,
    maintenanceModeSelector,
    globalConfigSelector,
    firstLoginSelector,
    isRealmLoadingSelector,
    dashboardBannerSelector,
};

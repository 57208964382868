import { styled } from '@mui/material/styles';

export const StyledHeader = styled('header')(({ theme }) => ({
    '.tooltip-wrapper': {
        '& > div': {
            padding: '8px',
            right: '16px',
            backgroundColor: theme.palette.black[500],
            '.MuiTooltip-arrow::before': {
                backgroundColor: theme.palette.black[500],
            },
        },
    },

    [theme.breakpoints.up('sm')]: {
        '.tooltip-wrapper': {
            '& > div': {
                right: '0',
            },
        },
    },
}));

import { useSelector } from 'react-redux';
import { translationsSel } from 'store/translations/selectors';
import { getStorageItem } from 'helpers/localStorage';

const generateTemplateString = (function () {
    let cache = {};

    function generateTemplate(template) {
        let fn = cache[template];

        if (!fn) {
            // Replace ${expressions} (etc) with ${map.expressions}.

            let sanitized = template
                .replace(/\$\{([\s]*[^;\s{]+[\s]*)\}/g, function (_, match) {
                    return `$\{map.${match.trim()}}`;
                })
                // Afterwards, replace anything that's not ${map.expressions}' (etc) with a blank string.
                .replace(/(\$\{(?!map\.)[^}]+\})/g, '');

            // eslint-disable-next-line no-new-func
            fn = Function('map', `return \`${sanitized}\``);
        }

        return fn;
    }

    return generateTemplate;
})();

export const useTranslation = () => {
    const translations = useSelector(translationsSel.translationsSelector);
    const selectedLang = getStorageItem('language');

    const t = (key, dynamicKey) => {
        const translation = translations[key];
        if (!translation) {
            return key;
        }

        let translationText = '';
        if (translation[selectedLang]) {
            translationText = translation[selectedLang];
        } else {
            translationText = translation['en'];
        }
        if (dynamicKey && Object.keys(dynamicKey).length !== 0 && translationText) {
            let generatedString = generateTemplateString(translationText);
            translationText = generatedString(dynamicKey);
        }

        if (translationText) return translationText;
    };

    return [t];
};

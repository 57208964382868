import { lazy } from 'react';

const Dashboard = lazy(() => import('views/pages/Dashboard'));
const FeaturedOffers = lazy(() => import('views/pages/FeaturedOffers'));
const Calendar = lazy(() => import('views/pages/Calendar'));
const Blog = lazy(() => import('views/pages/Blog'));
const PlayerProfile = lazy(() => import('views/pages/PlayerProfile'));
const Faction = lazy(() => import('views/pages/Faction'));
const LivingSkullsShop = lazy(() => import('views/pages/LivingSkullsShop'));
const Maintenance = lazy(() => import('views/pages/Maintenance'));
const Events = lazy(() => import('views/pages/Events'));
const BlogIframe = lazy(() => import('views/pages/Blog/components/BlogIframe'));

const ROUTES = [
    {
        path: '/:code',
        component: Dashboard,
        exact: true,
    },
    {
        path: '/:code/featured-offers',
        component: FeaturedOffers,
        exact: true,
    },
    {
        path: '/:code/blog',
        component: Blog,
        exact: true,
    },
    {
        path: '/:code/players/profile/:playerId',
        component: PlayerProfile,
        exact: true,
    },
    {
        path: '/:code/players/profile/:playerId/realm/:realmId',
        component: PlayerProfile,
        exact: true,
    },
    {
        path: '/:code/faction/:guildId',
        component: Faction,
        exact: true,
    },
    {
        path: '/:code/faction/:guildId/realm/:realmId',
        component: Faction,
        exact: true,
    },
    {
        path: '/:code/calendar',
        component: Calendar,
        exact: true,
    },
    {
        path: '/:code/living-skulls-shop',
        component: LivingSkullsShop,
        exact: true,
    },
    {
        path: '/:code/maintenance',
        component: Maintenance,
        exact: true,
    },
    {
        path: '/:code/events',
        component: Events,
        exact: true,
    },
    {
        path: '/:code/blog/:blogId',
        component: BlogIframe,
        exact: false,
    },
];

export default ROUTES;

import React from 'react';
import PropTypes from 'prop-types';

import { IconButton as MUIIconButton } from '@mui/material';
import { Box, Icon } from 'style-guide';
import theme from 'theme/theme';

const VARIANTS = {
    FILLED: 'filled',
    EMPTY: 'empty',
};

const VARIANT_STYLES = {
    [VARIANTS.FILLED]: {
        width: 16,
        height: 16,
        backgroundColor: theme.palette.grey[800],
        borderRadius: '50%',
    },
    [VARIANTS.EMPTY]: {},
};

const TYPES = {
    SQUARE: 'square',
    CIRCLE: 'circle',
};

const TYPE_STYLES = {
    [TYPES.SQUARE]: {
        width: 32,
        height: 32,
        borderRadius: '6px',
    },
    [TYPES.CIRCLE]: {
        width: 20,
        height: 20,
        borderRadius: '50%',
    },
};

const IconButton = ({ iconName, size, color, onClick, type, variant, sx, disabled }) => {
    const {
        palette: { black, grey, text },
    } = theme;

    return (
        <MUIIconButton
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
                backgroundColor: black[400],
                border: `1px solid ${grey[800]}`,
                boxSizing: 'border-box',
                p: 0,
                ml: '0 ! important',
                cursor: 'pointer',
                ...TYPE_STYLES[type],
                ...sx,
                color: text[300],
                '&:disabled': {
                    color: text[800],
                    backgroundColor: black[400],
                },
            }}
            disabled={disabled}
            onClick={onClick}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...VARIANT_STYLES[variant],
                }}
            >
                <Icon size={size} name={iconName} color={color} />
            </Box>
        </MUIIconButton>
    );
};

IconButton.propTypes = {
    iconName: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    size: PropTypes.number.isRequired,
    type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
    color: PropTypes.string,
    variant: PropTypes.string,
    sx: PropTypes.object,
    disabled: PropTypes.bool,
};

IconButton.defaultProps = {
    onClick: () => {},
    variant: VARIANTS.EMPTY,
    sx: {},
    disabled: false,
    color: 'inherit',
};

export default IconButton;

import { createSlice } from '@reduxjs/toolkit';

import { initialPlayerState } from './initialState';

export const playerSlice = createSlice({
    name: 'player',
    initialState: initialPlayerState(),
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setPlayerInfoLoading(state, action) {
            state.isPlayerInfoLoading = action.payload;
        },
        setPlayer(state, action) {
            state.player = action.payload;
        },
        setPlayerInfo(state, action) {
            state.playerInfo = action.payload;
        },
    },
});

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { ClickAwayListener, useTheme } from '@mui/material';
import { StyledDropDown } from './dropdown.styled';
import { Box, Icon, Typography } from 'style-guide';

const DropDown = ({ list, openUp, selected, onClickHandler, isForRegion }) => {
    const {
        palette: { yellow, text, black },
    } = useTheme();
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (selection) => {
        if (selected.Name === selection.Name) {
            return setIsOpen(false);
        }
        onClickHandler(selection);
        setIsOpen(false);
    };

    const toggleDropDownClick = () => setIsOpen(!isOpen);

    return (
        <ClickAwayListener
            onClickAway={() => {
                setIsOpen(false);
            }}
        >
            <div>
                <StyledDropDown>
                    <Box
                        sx={{
                            p: 3,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor: black[500],
                            border: `1px solid ${black[300]}`,
                            borderRadius: '6px',
                            cursor: 'pointer',
                            height: 40,
                        }}
                        onClick={toggleDropDownClick}
                        className="dropdown-wrapper"
                    >
                        {selected.Name && (
                            <Typography variant="p12" color={isForRegion ? yellow[600] : text[300]}>
                                {selected.Name}
                            </Typography>
                        )}
                        <Box className={isOpen ? 'with-transition' : ''}>
                            <Icon
                                size={8}
                                name={isOpen ? 'arrow-up' : 'arrow-down'}
                                color={text[800]}
                            />
                        </Box>
                    </Box>
                    {isOpen && (
                        <Box
                            className="dropdown-list"
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'column',
                                width: 1,
                                maxHeight: 200,
                                border: `1px solid ${black[300]}`,
                                left: 0,
                                mt: 2,
                                zIndex: 4,
                                overflowY: 'auto',
                                bottom: openUp ? 50 : 'auto',
                                borderRadius: '10px',
                            }}
                        >
                            <Box
                                component="ul"
                                sx={{
                                    p: 0,
                                    m: 0,
                                    backgroundColor: black[500],
                                }}
                            >
                                {list.map((item) => {
                                    return (
                                        <Box
                                            component="li"
                                            key={item.Id}
                                            onClick={() => handleSelect(item)}
                                            sx={{
                                                cursor: 'pointer',
                                                p: 3,
                                            }}
                                            className={
                                                item?.Id === selected?.Id ? 'active-element' : ''
                                            }
                                        >
                                            {item.Name && (
                                                <Typography
                                                    variant="p12"
                                                    color={text[800]}
                                                    fontWeight="bold"
                                                >
                                                    {item.Name}
                                                </Typography>
                                            )}
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                </StyledDropDown>
            </div>
        </ClickAwayListener>
    );
};

DropDown.defaultProps = {
    list: [],
    openUp: false,
    selected: {},
    onClickHandler: () => {},
    isForRegion: false,
};

DropDown.propTypes = {
    list: PropTypes.array,
    openUp: PropTypes.bool,
    selected: PropTypes.object,
    onClickHandler: PropTypes.func,
    isForRegion: PropTypes.bool,
};

export default memo(DropDown);

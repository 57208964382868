import moment from 'moment';
import { LANGUAGES } from '../constants/project.constants';
import { getStorageItem } from './localStorage';

/**
 * Generate Progress bar percents
 * @param startDate
 * @param endDate
 */
export const generateProgressDateInPercents = (startDate, endDate) => {
    const dateNow = moment().valueOf();
    const sDate = moment.parseZone(startDate).valueOf();
    const eDate = moment.parseZone(endDate).valueOf();
    const fullProgress = eDate - sDate;
    const currentProgress = dateNow - sDate;

    return (currentProgress * 100) / fullProgress;
};

/**
 * Generate Progress bar Date and time
 * @param endDate
 * @param t
 */
export const generateProgressDate = (endDate, t) => {
    //  Calculate duration between date now/startDate and event endDate
    //  Format: X Days Y Hours Remaining
    const duration = moment.duration(moment.parseZone(endDate).diff(moment()));
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (days && hours) {
        return `${days}d ${hours}h ${t('left')}`;
    } else if (hours && !days) {
        return `${hours}h ${minutes}m ${t('left')}`;
    } else if (!hours && days) {
        return `${days}d ${t('left')}`;
    }

    return `${minutes}m ${t('left')}`;
};

/**
 * Ordinal suffix
 * @param num
 * @param t
 */
export const ordinalSuffixOf = (num, t) => {
    const j = num % 10;
    const k = num % 100;

    if (j === 1 && k !== 11) {
        return `${num}${t('st')}`;
    }
    if (j === 2 && k !== 12) {
        return `${num}${t('nd')}`;
    }
    if (j === 3 && k !== 13) {
        return `${num}${t('rd')}`;
    }
    return `${num}${t('th')}`;
};

/**
 * Thousands Formatter With Dots
 * @param num
 */
export const thousandsFormatterWithDots = (num) => {
    if (!num) {
        return 0;
    }

    return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

/**
 * Thousands Formatter With Comas
 * @param num
 */
export const thousandsFormatterWithCommas = (num) => {
    if (!num) {
        return 0;
    }

    return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Format Price With Zeroes
 * @param num
 */
export const formatPriceWithZeroes = (num) => {
    if (!+num) {
        return 0;
    }

    if (Number.isInteger(+num)) {
        return `${num}.00`;
    }

    return (+num.toString()).toFixed(2);
};

/**
 * Calculate Progress bar percents for Events
 * @param score
 * @param levelScore
 */
export const progressPercentEvents = (score, levelScore) => (score / levelScore) * 100;

/**
 * Convert "camelCase" to "camel Case"
 * @param string
 */
export const insertSpaces = (string) => {
    if (!string) {
        return '';
    }

    return string
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3');
};

/**
 * Remove Spaces
 * @param string
 */
export const removeSpaces = (string) => {
    if (!string) {
        return '';
    }

    return string.replace(/ /g, '');
};

/**
 * Page load duration
 */
export const getPageLoadDuration = () => {
    const entriesValues = performance.getEntriesByType('navigation')[0];

    if (!entriesValues) {
        return 0;
    }

    return Math.floor(entriesValues.duration / 1000);
};

/**
 * Returns Correct Lang for i18n
 *
 * @return {object}
 *
 **/

export const getLanguage = () => {
    const regex = /\/(.*?)\/|^\/?(.*)/gm;
    const locationCode = regex.exec(window.location.pathname);
    const langPath = locationCode[1] ? locationCode[1] : locationCode[2];
    const lang =
        langPath && langPath !== getStorageItem('language')?.langId && LANGUAGES[langPath]
            ? LANGUAGES[langPath]
            : JSON.parse(sessionStorage.getItem('locale')) || LANGUAGES.en;

    return lang;
};

/**
 * Add language code link
 * @param {link} string
 * @return string
 **/

export const addLanguageCodeToLink = (link = '') => {
    return `/${getLanguage().langId}${link}`;
};

/**
 * Add zero before day/hour/minute/second
 * @param num | string
 * @return num | string
 **/

export const addZeroBefore = (time) => {
    return `${time}`.length === 2 ? time : `0${time}`;
};

/**
 * Calculate text width
 * @param text | string
 * @return fontSize | number
 */

const widths = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125,
    0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875,
    0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625,
    0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625,
    0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375,
    0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625,
    0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625,
    0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875,
    0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5,
    0.3546875, 0.259375, 0.353125, 0.5890625,
];
const avg = 0.5279276315789471;

export const textWidth = (text, fontSize) =>
    Array.from(text).reduce((acc, cur) => acc + (widths[cur.charCodeAt(0)] ?? avg), 0) * fontSize;

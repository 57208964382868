import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scopelyId } from 'voluum';
import PropTypes from 'prop-types';
import { Hidden, useMediaQuery, useTheme } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { Backdrop, Box } from 'style-guide';

import { GA_ID } from 'constants/analytic.constants';
import { APP_REDIRECT_URI } from 'constants/api.constants';

import { globalOp, globalSel } from 'store/global';
import { blogOp } from 'store/blog';
import { featuredOffersOp } from 'store/featured-offers';
import { translationsSel } from 'store/translations';

import { getStorageItem } from 'helpers/localStorage';
import { getPageLoadDuration } from 'helpers/utils';
import { gtagEvent } from 'helpers/GA4Helper';

import Header from './Header';
import LeftSidebar from './LeftSidebar';
import Main from './Main';

const Layout = ({ children }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const currentUser = useSelector(globalSel.currentUserSelector);
    const authenticated = useSelector(globalSel.isAuthSelector);
    const selectedLanguage = useSelector(translationsSel.selectedLanguageSelector);
    const md = useMediaQuery(theme.breakpoints.down('md'));

    const [openLeftSidebar, setOpenLeftSidebar] = useState(false);

    useEffect(() => {
        gtagEvent({
            action:
                pathname === '/'
                    ? '/dashboard/viewed'
                    : pathname.includes('faction')
                    ? 'faction/profile/viewed'
                    : pathname.includes('player')
                    ? 'player/profile/viewed'
                    : pathname + '/viewed',
            event_category: 'Custom page view',
            event_label: 'CUSTOM_PAGE_VIEW',
            userId: `${currentUser?.UserId}`,
        });

        return history.listen(() => {
            window.scrollTo({ top: 0, behavior: 'auto' });

            gtagEvent({
                action: pathname === '/' ? '/dashboard/viewed' : `${pathname}/viewed`,
                event_category: 'Custom page view',
                event_label: 'CUSTOM_PAGE_VIEW',
                value: pathname === '/' ? '/dashboard/viewed' : `${pathname}/viewed`,
                userId: `${currentUser?.UserId}`,
            });
        });
    }, [dispatch, history, pathname, currentUser]);

    useEffect(() => {
        dispatch(globalOp.getGlobalConfigs());
        dispatch(blogOp.getBlogCategory());
        dispatch(globalOp.getDashboardBanner());
    }, [dispatch, selectedLanguage.langId]);
    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', GA_ID, {
                page_title: window.location.pathname,
                page_path: window.location.pathname,
            });
        }
    }, []);

    useEffect(() => {
        if (openLeftSidebar && md) {
            document.body.style['overflow-y'] = 'hidden';
        } else {
            document.body.style['overflow-y'] = 'auto';
        }
    }, [openLeftSidebar, md]);

    // Login with scopely...
    const scopelyLogin = useCallback(async () => {
        const currentLocation = window.location.href;

        if (scopelyId.isAuthorizationUri(currentLocation)) {
            const previousLocation = scopelyId.previousLocation();

            if (previousLocation) {
                history.push(
                    previousLocation.pathname.replace(`/${getStorageItem('language')}`, ''),
                );
            }
            const authResult = await scopelyId.authorizationResult(currentLocation);

            if (authResult.isSuccessful()) {
                dispatch(globalOp.loginAsync(authResult.getResponse().getAccessToken(), 'scopely'));
            } else {
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'error.on.login',
                });
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const loginProcessing = getStorageItem('loginProcessing');

        if (!authenticated && loginProcessing === 'withScopely') {
            scopelyLogin();
        }
        // Do setup for non-authorizing "normal" app flow...
        // eslint-disable-next-line
    }, [authenticated, scopelyLogin]);

    useEffect(() => {
        if (
            authenticated &&
            currentUser &&
            window.location &&
            window.location?.search?.includes('status=done')
        ) {
            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: 'reward-success',
                }),
            );

            gtagEvent({
                action: `${pathname}/${window.location.search?.split('invoice_id=')[1]}/purchased`,
                event_category: 'Offer purchased',
                event_label: 'OFFER_PURCHASED',
                value: `${pathname}/${window.location.search?.split('invoice_id=')[1]}/purchased`,
                userId: `${currentUser?.UserId}`,
                offerId: `${window.location.search?.split('invoice_id=')[1]}`,
                offerName: `${getStorageItem('purchasedName')}`,
            });
            dispatch(featuredOffersOp.updateFeaturedOffersCache());
            dispatch(globalOp.updateUserBalance());
            history.push(window.location.pathname);
        }
        // eslint-disable-next-line
    }, [window.location, authenticated, currentUser]);

    const getCurrentPathName = () => {
        const fullPath = window.location.pathname.replace('/', '');
        const slashIndex = fullPath.indexOf('/');

        return slashIndex !== -1 ? fullPath.substring(0, slashIndex) : fullPath;
    };

    useEffect(() => {
        const duration = getPageLoadDuration();
        const currentKey = getCurrentPathName();

        duration >= 20 &&
            window?.DD_RUM?.addError(`page-load-${currentKey.replace('/', '')}`, {
                host: APP_REDIRECT_URI,
                path: window.location.pathname.replace('/', ''),
            });
    }, []);

    return (
        <Fragment>
            <Header setOpenLeftSidebar={setOpenLeftSidebar} />
            <Box display="flex" height={1}>
                <Hidden mdUp>
                    <Backdrop
                        open={openLeftSidebar}
                        zIndex={1150}
                        onClick={() => setOpenLeftSidebar(false)}
                    />
                </Hidden>

                <LeftSidebar
                    openLeftSidebar={openLeftSidebar}
                    setOpenLeftSidebar={setOpenLeftSidebar}
                />
                <Main>{children}</Main>
            </Box>
        </Fragment>
    );
};

Layout.propTypes = {
    children: PropTypes.node,
};

export default Layout;

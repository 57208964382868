import { styled } from '@mui/material/styles';
import { Box } from 'style-guide';

import browserSupport_bg from 'assets/images/browser_support_bg.png';
import center_bg from 'assets/images/mainteance_cent_bg.png';
import browserSupportBg from 'assets/images/bg_browsersuppot.png';

export const StyledBrowserSupport = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',

    '.WP-BrowserSupport-bg': {
        '&:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 0,
            backgroundImage: `url(${browserSupportBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top',
        },

        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 2,
            backgroundImage: `url(${browserSupport_bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            backgroundPosition: 'center',
            [theme.breakpoints.down('md')]: {
                backgroundSize: '120%',
            },
            [theme.breakpoints.down('sm')]: {
                backgroundSize: '130%',
            },
        },

        [theme.breakpoints.down('sm')]: {
            '&:before': {
                background: 'none',
            },
        },
    },

    '.WP-BrowserSupport-center-bg': {
        backgroundImage: `url(${center_bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
}));

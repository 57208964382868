export const socialLinkList = [
    {
        linkHref: 'https://www.facebook.com/WalkingDeadRoadtoSurvival',
        linkId: 'facebook-group-link-footer',
        iconId: 'facebook-icon-footer',
        iconName: 'facebook-square',
    },
    {
        linkHref: 'https://www.youtube.com/channel/UCa0vOFJyxl2AfyI_M0shMjw/featured',
        linkId: 'youtube-channel-link-footer',
        iconId: 'youtube-icon-link',
        iconName: 'youtube',
    },
    {
        linkHref: 'https://twitter.com/walkingdeadrts',
        linkId: 'twitter-link-channel-footer',
        iconId: 'twitter-icon-footer',
        iconName: 'twitter',
    },
    {
        linkHref: 'https://www.instagram.com/walkingdeadrts/',
        linkId: 'instagram-link-footer',
        iconId: 'instagram-icon-footer',
        iconName: 'instagram',
    },
];

import React from 'react';

import { Box } from 'style-guide';
import { useTheme, Skeleton, Hidden } from '@mui/material';

const PlayerProfileInfoSkeleton = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.black[700],
                p: 2,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mr: 6 }}>
                <Skeleton variant="circular" animation="wave" sx={{ width: 16, height: 16 }} />
                <Skeleton variant="text" animation="wave" sx={{ width: 50, height: 20 }} />
            </Box>
            <Hidden smDown>
                <Box sx={{ mr: 2 }}>
                    <Skeleton variant="text" animation="wave" sx={{ width: 70, height: 18 }} />
                    <Skeleton variant="text" animation="wave" sx={{ width: 70, height: 18 }} />
                </Box>
            </Hidden>
            <Skeleton
                variant="circular"
                animation="wave"
                sx={{ width: 32, height: 32, mr: [0, 4] }}
            />
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ width: 8, height: 6, ml: [3, 1] }}
            />
        </Box>
    );
};

export default PlayerProfileInfoSkeleton;

import { fetch } from 'services';

import { globalOp } from 'store/global';

import { eventsSlice } from './eventsSlice';

const getEvents = (isLoggedIn) => {
    const { setLoading, setEvents } = eventsSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));

        const endPoint = isLoggedIn ? `/events` : `/events/global`;

        try {
            const { data: events } = await fetch({ endPoint });

            dispatch(setEvents(events));
        } catch (error) {
            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'something.went.wrong',
                }),
            );
        }

        dispatch(setLoading(false));
    };
};

const claimMilestone = (
    eventId,
    cycle,
    milestone,
    startTimestamp,
    setIsCardLoading,
    isMobile,
    moveSwiper,
) => {
    const { setMilestoneLoading, setEvents } = eventsSlice.actions;

    return async (dispatch) => {
        dispatch(setMilestoneLoading(true));

        try {
            const { data } = await fetch({
                endPoint: `/payments/claim`,
                method: 'POST',
                body: {
                    eventId,
                    cycle,
                    milestone,
                    startTimestamp,
                },
            });

            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: 'reward-success',
                    message: 'reward.claimed',
                }),
            );

            setIsCardLoading(false);
            dispatch(setEvents(data));
            if (!isMobile) {
                moveSwiper();
            }
        } catch (error) {
            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'something.went.wrong',
                }),
            );
        }

        dispatch(setMilestoneLoading(false));
    };
};

export const eventsOp = {
    getEvents,
    claimMilestone,
};

import { styled } from '@mui/material/styles';

export const StyledLeftSidebarNav = styled('nav')(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.black[300]}`,

    '& .nav-link': {
        display: 'block',
        color: theme.palette.text[600],
        transition: 'color 200ms ease-in-out',
        whiteSpace: 'nowrap',
        mb: 2,
        textDecoration: 'none',

        '&:last-child': {
            mb: 0,
        },

        '& .item-name p': {
            whiteSpace: 'break-spaces',
        },

        '&:hover': {
            color: theme.palette.text[300],

            '& .item-name p': {
                color: theme.palette.text[300],
            },
        },
    },

    '& .active-link': {
        color: theme.palette.text[300],
        background:
            'linear-gradient(270deg, rgba(150, 148, 138, 0.1) 0%, rgba(250, 247, 230, 0.1) 100%)',

        '& .item-name p': {
            color: theme.palette.text[300],
        },
    },
    '& hr': {
        width: 'calc(100% - 40px)',
        border: `1px solid ${theme.palette.black[300]}`,
        display: 'block',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

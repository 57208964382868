import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'hooks';

import { Box, Typography } from 'style-guide';
import { StyledBrowserSupport } from './BrowserSupport.styled';
import google_icon from 'assets/images/google_icon.svg';
import firefox_icon from 'assets/images/firefox_icon.svg';
import safari_icon from 'assets/images/safari_icon.svg';
import edge_icon from 'assets/images/edge_icon.svg';

const supportedButtons = [
    { icon: google_icon, text: 'Google Chrome' },
    { icon: firefox_icon, text: 'Mozilla Firefox' },
    { icon: safari_icon, text: 'Safari' },
    { icon: edge_icon, text: 'Edge' },
];

const BrowserSupport = () => {
    const theme = useTheme();
    const [t] = useTranslation();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <StyledBrowserSupport className="WP-BrowserSupport-root" height="100%" margin={0}>
            <Box className="WP-BrowserSupport-bg" />
            <Box
                sx={{
                    width: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    className="WP-BrowserSupport-center-bg"
                    sx={{
                        minWidth: [1, 844],
                        height: 334,
                        zIndex: 1,
                        mt: [5, 15],
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: [48, 57],
                        zIndex: 3,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h4' : isTablet ? 'h2' : 'h1'}
                        className="WP-BrowserSupport-title"
                        color={theme.palette.text[600]}
                        mb={[10, 11]}
                    >
                        {t('supported.browsers.title')}
                    </Typography>
                    {supportedButtons.map((supportButton, index) => {
                        return (
                            <Box
                                key={index}
                                className="WP-BrowserSupport-button"
                                sx={{
                                    width: [280, 300],
                                    height: 70,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'rgba(255,255,255,0.05)',
                                    border: '1px solid rgba(255, 255, 255, 0.1)',
                                    borderRadius: '20px',
                                    mb: 5,
                                    zIndex: 5,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: [270, 290],
                                        height: 60,
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '18px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                        backdropFilter: 'blur(14px)',
                                    }}
                                >
                                    <Box
                                        component="img"
                                        alt="button icon"
                                        src={supportButton.icon}
                                        mx={3}
                                    />
                                    <Typography variant="h4" color={theme.palette.text[300]}>
                                        {supportButton.text}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </StyledBrowserSupport>
    );
};

export default BrowserSupport;

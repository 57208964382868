export const initialBlogState = () => {
    return {
        isBlogLoading: true,
        isMoreLoading: false,
        blogCategories: [],
        bannerData: [],
        blogItems: {
            data: [],
        },
        selectedCategory: { value: 'All' },
    };
};

import React from 'react';

import { useTheme } from '@mui/material';
import { Box, Typography } from 'style-guide';
import ModalIcon from './ModalIcon';

import { useTranslation } from 'hooks';

import no_connected_account from 'assets/images/no_connected_account.svg';

const NotLinkedAccountModal = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const {
        palette: { text },
    } = theme;

    return (
        <Box display="flex" flexDirection="column" alignItems="center" maxWidth={320}>
            <ModalIcon icon={no_connected_account} />
            <Typography mb={2} fontWeight="bold" color={text[300]} textAlign="center" variant="h4">
                {t('no.connected.account')}
            </Typography>
            <Typography color={text[600]} textAlign="center">
                {t('download.game.account')}.
            </Typography>
        </Box>
    );
};

export default NotLinkedAccountModal;

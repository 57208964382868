import React from 'react';
import PropTypes from 'prop-types';

import { Link as MUILink } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledLink = styled(MUILink)(() => ({
    textDecoration: 'none',
    '&:hover': {
        boxShadow: 'none',
    },
}));

const Link = ({ color, href, blank, children }) => {
    return (
        <StyledLink target={blank ? '_blank' : null} href={href} fontFamily="inherit" color={color}>
            {children}
        </StyledLink>
    );
};

Link.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    href: PropTypes.string.isRequired,
    color: PropTypes.string,
    blank: PropTypes.bool,
};

Link.defaultProps = {
    noWrap: false,
    color: 'inherit',
    blank: false,
};

export default Link;

import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';

import { ClickAwayListener, Tooltip as MUITooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const StyledTooltip = styled(({ className, ...props }) => (
    <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.black[300],
    },
    [`& .${tooltipClasses.tooltip}`]: {
        color: theme.palette.text[300],
        padding: '16px 20px',
        borderRadius: '10px',
        boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)',
        backgroundColor: theme.palette.black[300],
        ...theme.typography.p10,
    },
}));

const Tooltip = forwardRef(({ title, children, placement, ...props }, ref) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const handleTooltipClose = () => setOpenTooltip(false);
    const handleTooltipOpen = () => setOpenTooltip(true);

    return isBrowser ? (
        <StyledTooltip ref={ref} title={title} arrow placement={placement} {...props}>
            <div>{children}</div>
        </StyledTooltip>
    ) : (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <StyledTooltip
                    ref={ref}
                    title={title}
                    open={openTooltip}
                    placement={placement}
                    arrow
                    {...props}
                >
                    <div onClick={handleTooltipOpen}>{children}</div>
                </StyledTooltip>
            </div>
        </ClickAwayListener>
    );
});

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    placement: PropTypes.string,
};

Tooltip.defaultProps = {
    placement: 'top',
};

export default Tooltip;

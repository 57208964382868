import React, { useEffect, useState } from 'react';
import { useTranslation } from 'hooks';
import { Box, Button, Checkbox, Input, Link, Typography } from 'style-guide';
import { MAILCHIMP_API, SCOPELY_URLS } from 'constants/api.constants';
import { StyledSubscribe } from './Subscribe.styled';
import { emailValidator } from 'helpers/emailValidator';
import { useTheme } from '@mui/material';

const EMAIL_INPUT_LENGTH = 50;

const Subscribe = () => {
    const [t] = useTranslation();
    const theme = useTheme();

    const [inputValue, setInputValue] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isAgreementFilled, setIsAgreementFilled] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(null);

    const setDefaultValues = () => setSubmitted(true);

    const handleInputChange = ({ target: { value } }) => {
        if (value.length >= EMAIL_INPUT_LENGTH) {
            return;
        }

        if (!emailValidator(value) && value.length > 0) {
            setIsValidEmail(false);
        } else {
            setIsValidEmail(true);
        }

        setInputValue(value);
    };

    useEffect(() => {
        if (submitted && inputValue !== '') {
            setInputValue('');
            setSubmitted(false);
            setIsAgreementFilled(false);
        }
        // eslint-disable-next-line
    }, [submitted]);

    const isDisabled = inputValue && inputValue.length && isAgreementFilled && isValidEmail;

    return (
        <Box component={StyledSubscribe} mb={10} px={5} width={1}>
            <Box borderTop={`1px solid ${theme.palette.black['300']}`} mb={2} pt={5}>
                <Typography
                    variant="p10"
                    fontWeight="bold"
                    color={theme.palette.text[600]}
                    uppercase
                >
                    {t('join.our.mailing.list')}
                </Typography>
            </Box>
            <Box mb={4}>
                <Typography color={theme.palette.text[600]} variant="p10">
                    {t('sign.up.for.special.updates.on.events.deals.and.more')}
                </Typography>
            </Box>
            <form
                action={MAILCHIMP_API.MAILCHIMP_URL}
                onSubmit={setDefaultValues}
                method="post"
                target="_blank"
            >
                <Box>
                    <Box mb={3}>
                        <Input
                            type="text"
                            name="EMAIL"
                            id="mce-EMAIL"
                            value={inputValue}
                            placeholder={t('email.address')}
                            autoComplete="off"
                            onChange={handleInputChange}
                        />
                    </Box>
                    {!isValidEmail && inputValue.length ? (
                        <Typography variant="p10" color={theme.palette.red[600]}>
                            {t('invalid.email')}
                        </Typography>
                    ) : null}
                    <Button disabled={!isDisabled} type="solid" htmlType="submit">
                        {t('subscribe')}
                    </Button>
                </Box>
                <Box mt={6} display="flex" className="WP-Subscribe-terms-policy-wrapper">
                    <Box mr={2}>
                        <Checkbox
                            id={MAILCHIMP_API.CHECKBOX_ID}
                            name={MAILCHIMP_API.CHECKBOX_NAME}
                            onChange={() => setIsAgreementFilled(!isAgreementFilled)}
                            checked={isAgreementFilled}
                        />
                    </Box>

                    <Typography variant="p12" color={theme.palette.text[600]}>
                        {t('mailchimp.agreement.text')}({' '}
                        <Link href={SCOPELY_URLS.PRIVACY_POLICY}>{t('privacy.policy')}</Link>{' '}
                        {t('and')} <Link href={SCOPELY_URLS.TERMS}>{t('terms.of.service')}</Link> )
                    </Typography>
                </Box>
            </form>
        </Box>
    );
};

export default Subscribe;

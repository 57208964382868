import { createSelector } from 'reselect';

const blogSelector = (state) => state.blog;

const isLoadingSelector = createSelector([blogSelector], (blog) => blog.isBlogLoading);
const blogItemsSelector = createSelector([blogSelector], (blog) => blog.blogItems);
const blogMoreLoadingSelector = createSelector([blogSelector], (blog) => blog.isMoreLoading);
const blogCategoriesSelector = createSelector([blogSelector], (blog) => blog.blogCategories);
const bannerDataSelector = createSelector([blogSelector], (blog) => blog.bannerData);
const selectedCategorySelector = createSelector([blogSelector], (blog) => blog.selectedCategory);

export const blogSel = {
    isLoadingSelector,
    blogItemsSelector,
    blogMoreLoadingSelector,
    blogCategoriesSelector,
    bannerDataSelector,
    selectedCategorySelector,
};

import { createSelector } from 'reselect';

const eventsSelector = (state) => state.events;

const isLoadingSelector = createSelector([eventsSelector], (events) => events.isLoading);
const eventsDataSelector = createSelector([eventsSelector], ({ events }) => events);
const milestoneLoadingSelector = createSelector(
    [eventsSelector],
    (events) => events.milestoneLoading,
);

export const eventsSel = {
    isLoadingSelector,
    eventsDataSelector,
    milestoneLoadingSelector,
};

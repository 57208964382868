import { createSlice } from '@reduxjs/toolkit';

import { initialFactionState } from './initialState';

export const factionSlice = createSlice({
    name: 'faction',
    initialState: initialFactionState(),
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setFaction(state, action) {
            state.faction = action.payload;
        },
        setIsFactionCheckInLoading(state, action) {
            state.isFactionCheckInLoading = action.payload;
        },
        setFactionCheckIn(state, action) {
            state.factionCheckIn = action.payload;
        },
    },
});

import React, { Suspense, useCallback, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { LANGUAGES } from 'constants/project.constants';

import { useDispatch, useSelector } from 'react-redux';
import { translationsSel, translationsOp } from 'store/translations';
import { globalOp, globalSel } from 'store/global';
import { globalSlice } from 'store/global/globalSlice';

import ROUTES from 'router/routes';
import ConnectionLostComponent from 'views/components/ConnectionLost';
import Maintenance from 'views/pages/Maintenance';
import Layout from 'views/layout/Layout';
import NotFoundPage from 'views/pages/NotFoundPage/NotFoundPage';
import { useOnline } from 'hooks';

import { refreshAccessToken } from 'services';
import { addLanguageCodeToLink } from 'helpers/utils';
import { notAvailableRouters, pageMaintenanceModeRenderer } from 'helpers/appHelpers';
import { getStorageItem, setStorageItem } from 'helpers/localStorage';

const Router = () => {
    const online = useOnline();
    const dispatch = useDispatch();
    const selectedLanguage = useSelector(translationsSel.selectedLanguageSelector);
    const globalConfig = useSelector(globalSel.globalConfigSelector);
    const maintenanceMode = useSelector(globalSel.maintenanceModeSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const authenticated = useSelector(globalSel.isAuthSelector);
    const isUserLoading = useSelector(globalSel.isLoadingSelector);
    const { common: commonTranslationsLoading } = useSelector(translationsSel.isLoadingSelector);

    const accessToken = getStorageItem('accessToken');
    const refreshToken = getStorageItem('refreshToken');

    const handleRealmChange = useCallback(async () => {
        await refreshAccessToken(refreshToken);
        dispatch(globalOp.authMe(accessToken));
    }, [refreshToken, dispatch, accessToken]);

    useEffect(() => {
        window.addEventListener('realm', handleRealmChange);

        return () => {
            window.removeEventListener('realm', handleRealmChange);
        };
    }, [handleRealmChange]);

    useEffect(() => {
        if (accessToken && !authenticated) {
            dispatch(globalOp.authMe(accessToken));
        }
        if (!accessToken) {
            dispatch(globalSlice.actions.setLoading(false));
        }
    }, [authenticated, accessToken, dispatch]);

    return (
        <BrowserRouter>
            <Route path="/:lang/">
                {({ match }) => {
                    const oldLang = getStorageItem('language');
                    const params = match ? match.params : {};
                    const lang = LANGUAGES[params.lang] ? LANGUAGES[params.lang] : selectedLanguage;
                    const { langId } = lang;
                    const { pathname } = window.location;

                    if (oldLang && oldLang !== selectedLanguage?.langId) {
                        setStorageItem('language', selectedLanguage?.langId);
                        const path = pathname.replace(`/${langId}`, '');
                        return <Redirect to={`/${selectedLanguage.langId}${path}`} />;
                    } else if (!pathname.includes(`/${lang?.langId}`)) {
                        if (window.location.search.includes('code=')) {
                            setStorageItem(
                                'ScopelyLogin',
                                window.location.search.split('&')[0].split('=')[1],
                            );
                        } else if (!pathname.includes(`/${langId}`)) {
                            return (
                                <Redirect
                                    to={pathname === '/' ? `/${langId}` : `/${langId}${pathname}`}
                                />
                            );
                        }
                    }

                    if (oldLang && oldLang !== lang.langId) {
                        setTimeout(() => {
                            dispatch(translationsOp.setLanguage(lang));
                        }, 0);
                    }

                    setStorageItem('language', selectedLanguage?.langId);

                    return !online ? (
                        <ConnectionLostComponent />
                    ) : (
                        globalConfig &&
                            (!maintenanceMode.global ||
                            (typeof globalConfig.global !== 'undefined' && !globalConfig.global) ? (
                                <Switch>
                                    <Route path="/">
                                        <Maintenance />
                                    </Route>
                                </Switch>
                            ) : (
                                !commonTranslationsLoading && (
                                    <Layout>
                                        <Suspense fallback={null}>
                                            <Switch>
                                                {ROUTES.map((route) => (
                                                    <Route
                                                        key={route.path}
                                                        path={route.path}
                                                        exact={route.exact}
                                                    >
                                                        {pageMaintenanceModeRenderer(
                                                            route.path,
                                                            maintenanceMode,
                                                        ) ||
                                                        notAvailableRouters(globalConfig).includes(
                                                            route.path,
                                                        ) ? (
                                                            <Maintenance />
                                                        ) : (
                                                            <route.component />
                                                        )}
                                                    </Route>
                                                ))}
                                                <Route path="/featured-offers">
                                                    <Redirect to="/featured-offers" />
                                                </Route>
                                                <Route path={addLanguageCodeToLink('/players')}>
                                                    {!isUserLoading && (
                                                        <Redirect
                                                            to={addLanguageCodeToLink(
                                                                `/players/profile/${
                                                                    currentUser
                                                                        ? `${currentUser?.FriendCode}/realm/${currentUser?.CurrentRealm}`
                                                                        : 'logged-out'
                                                                }`,
                                                            )}
                                                        />
                                                    )}
                                                </Route>
                                                <Route path={addLanguageCodeToLink('/faction')}>
                                                    {!isUserLoading && (
                                                        <Redirect
                                                            to={addLanguageCodeToLink(
                                                                `/faction/${
                                                                    currentUser?.Guild?.Id
                                                                        ? `${currentUser?.Guild?.Id}/realm/${currentUser?.CurrentRealm}`
                                                                        : window.location.pathname.includes(
                                                                              'logged-out',
                                                                          )
                                                                        ? 'logged-out'
                                                                        : 'not-joined'
                                                                }`,
                                                            )}
                                                        />
                                                    )}
                                                </Route>

                                                <Route component={NotFoundPage} />
                                            </Switch>
                                        </Suspense>
                                    </Layout>
                                )
                            ))
                    );
                }}
            </Route>
        </BrowserRouter>
    );
};

export default Router;

import { fetch } from 'services';

import { globalOp } from 'store/global';

import { livingSkullsSlice } from './livingSkullsSlice';

const getLivingSkulls = (isLoggedIn) => {
    const { setLoading, setLivingSkullsItems } = livingSkullsSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const endPoint = isLoggedIn ? `/offers/living-skulls` : `/offers/living-skulls/global`;
            const { data: livingSkullsOffers } = await fetch({ endPoint });

            dispatch(setLivingSkullsItems(livingSkullsOffers));
        } catch (error) {
            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'error.on.living.skulls',
                }),
            );
        }

        dispatch(setLoading(false));
    };
};

export const livSkullOp = {
    getLivingSkulls,
};

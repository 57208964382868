import { fetch } from 'services';

import { globalSlice } from './globalSlice';

import { getStorageItem, removeStorageItem, setStorageItem } from 'helpers/localStorage';
import { gtagEvent } from 'helpers/GA4Helper';

import { helpshiftExtension, scopelyId } from 'voluum';
import { getStore } from '../configureStore';

// Snackbar handling
const handleOpenSnackbar = (data) => (dispatch) => {
    const { setSnackbar } = globalSlice.actions;

    dispatch(setSnackbar(data));
};

const handleCloseSnackbar = () => (dispatch) => {
    const { setSnackbar } = globalSlice.actions;

    dispatch(
        setSnackbar({
            open: false,
            variant: '',
        }),
    );
};

// Modal handling
const handleOpenModal = (data) => (dispatch) => {
    const { setModal } = globalSlice.actions;

    dispatch(setModal(data));
};

const handleCloseModal = () => (dispatch) => {
    const { setModal } = globalSlice.actions;

    dispatch(
        setModal({
            open: false,
            variant: '',
        }),
    );
};

// First login handling
const handleFirstLogin = (data) => (dispatch) => {
    const { setFirstLogin } = globalSlice.actions;

    dispatch(setFirstLogin(data));
};

// User authentication
const authMeAsync = (accessToken) => {
    try {
        return fetch({
            endPoint: '/auth/me',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    } catch (error) {
        return error;
    }
};

// Get current platform for gtagEvent
const getPlatform = (user, platform) => {
    const platforms = {
        scopely: {
            registered: {
                action: `scopelyId_registered/${user.FriendCode}`,
                event_category: 'ScopelyId Registered',
                event_label: 'SCOPELYID_REGISTERED',
            },
            loggedin: {
                action: `scopelyId_loggedin/${user.FriendCode}`,
                event_category: 'ScopelyId Logged in',
                event_label: 'SCOPELYID_LOGGED_IN',
            },
        },
        facebook: {
            registered: {
                action: `fb_registered/${user.FriendCode}`,
                event_category: 'Facebook Registered',
                event_label: 'FB_REGISTERED',
            },
            loggedin: {
                action: `fb_loggedin/${user.FriendCode}`,
                event_category: 'Facebook Logged in',
                event_label: 'FB_LOGGED_IN',
            },
        },
    };

    return platforms[platform];
};

const loginAsync = (token, loginType) => {
    const { setCurrentUser, setLoading, setAuthenticated } = globalSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const { data: login } = await fetch({
                endPoint: `/auth/${loginType}/login`,
                method: 'POST',
                body: { token },
            });
            const { data: user } = await authMeAsync(login.accessToken);

            dispatch(setCurrentUser(user));
            dispatch(setAuthenticated(true));
            dispatch(handleFirstLogin(true));
            setStorageItem('accessToken', login.accessToken);
            setStorageItem('refreshToken', login.refreshToken);
            setStorageItem('user_Id', user.UserId);

            if (login.firstTime) {
                gtagEvent(getPlatform(user, loginType).registered);
            } else {
                gtagEvent(getPlatform(user, loginType).loggedin);
            }
        } catch (error) {
            if (error.status === 500 && error.data?.message === 'Not linked account') {
                dispatch(
                    handleOpenModal({
                        open: true,
                        variant: 'not-linked-account',
                    }),
                );
            } else {
                handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'error.on.login',
                });
            }
        }

        dispatch(setLoading(false));
    };
};

const authMe = (accessToken) => {
    const { setCurrentUser, setLoading, setAuthenticated } = globalSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const { data: user } = await authMeAsync(accessToken);

            dispatch(setCurrentUser(user));
            window?.DD_RUM?.addAction('logins-count', { accountId: user.userId });
            dispatch(setAuthenticated(true));
            helpshiftExtension.setGameUserId(user.UserId);
        } catch (error) {
            if (error.status === 401) {
                dispatch(setCurrentUser(null));
                dispatch(setAuthenticated(false));
                removeStorageItem('accessToken');
                removeStorageItem('refreshToken');
            }
        }

        dispatch(setLoading(false));
    };
};

const getLeaderboards = (realmId) => {
    const { setLeaderboardsLoading, setGlobalLeaderboards } = globalSlice.actions;

    return async (dispatch) => {
        dispatch(setLeaderboardsLoading(true));

        try {
            const { data: leaderboards } = await fetch({
                endPoint: `/leaderboards/total-scores/${realmId}`,
            });

            dispatch(setGlobalLeaderboards(leaderboards));
        } catch (error) {
            handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'can.not.get.leaderboard.data',
            });
        }

        dispatch(setLeaderboardsLoading(false));
    };
};

const logout = () => async (dispatch) => {
    const { setCurrentUser, setAuthenticated, currentUser } = globalSlice.actions;

    helpshiftExtension.unsetGameUserId(currentUser?.UserId);
    dispatch(setCurrentUser(null));
    dispatch(setAuthenticated(false));
    removeStorageItem('accessToken');
    removeStorageItem('refreshToken');
    removeStorageItem('user_Id');

    if (getStorageItem('loginProcessing') === 'withScopely') {
        const currentLocation = window.location.href;
        await scopelyId.logoutResult(currentLocation);
        await scopelyId.logout(currentLocation);
    }
};

const getGlobalConfigs = () => {
    const { setPortalConfigs } = globalSlice.actions;
    return async (dispatch) => {
        try {
            const { data: killSwitch } = await fetch({
                endPoint: `/configs/killSwitch`,
            });

            const selectGlobalConfig = (state) => {
                return state.global.globalConfig;
            };

            const store = getStore();
            const currentGlobalConfig = selectGlobalConfig(store.getState());
            if (JSON.stringify(currentGlobalConfig) !== JSON.stringify(killSwitch.value)) {
                dispatch(setPortalConfigs(killSwitch.value));
            }
        } catch (error) {
            handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'error.on.configs',
            });
        }
    };
};

const getDashboardBanner = () => {
    const { setDashboardBanner } = globalSlice.actions;

    return async (dispatch) => {
        try {
            const { data: banner } = await fetch({
                endPoint: '/configs/banner',
            });

            dispatch(setDashboardBanner(banner));
        } catch (error) {
            handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'error.on.configs',
            });
        }
    };
};

const updateUserBalance = () => {
    const { setUserLivingSkulls } = globalSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch({ endPoint: `/users` });

            dispatch(setUserLivingSkulls(data.LivingSkulls));
        } catch (error) {
            handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'error.on.configs',
            });
        }
    };
};

const setCurrentRealm = (realmId) => {
    const { setRealmLoading, setCurrentUser, setAuthenticated } = globalSlice.actions;

    return async (dispatch) => {
        try {
            dispatch(setRealmLoading(true));

            const { data } = await fetch({
                endPoint: `/users/update/currentRealm`,
                method: 'PUT',
                body: { realmId },
            });

            if (data.accessToken) {
                setStorageItem('accessToken', data.accessToken);

                const user = await fetch({ endPoint: '/auth/me' });

                if (user.data) {
                    dispatch(setCurrentUser(user.data));
                    dispatch(setAuthenticated(true));
                    setStorageItem('refreshToken', data.refreshToken);
                }
            }
        } catch (error) {
            handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'error.on.configs',
            });
        }
        dispatch(setRealmLoading(false));
    };
};

const setMaintenance = (path) => {
    const { setMaintenanceMode } = globalSlice.actions;

    return (dispatch) => dispatch(setMaintenanceMode(path));
};

export const globalOp = {
    loginAsync,
    handleCloseSnackbar,
    handleOpenSnackbar,
    handleOpenModal,
    handleCloseModal,
    logout,
    authMe,
    getLeaderboards,
    setMaintenance,
    getGlobalConfigs,
    setCurrentRealm,
    handleFirstLogin,
    getDashboardBanner,
    updateUserBalance,
};

export const LANGUAGES = {
    en: { Name: 'English', Id: 1, langId: 'en' },
    fr: { Name: 'Français', Id: 2, langId: 'fr' },
    it: { Name: 'Italiano', Id: 3, langId: 'it' },
    de: { Name: 'Deutsch', Id: 4, langId: 'de' },
    es: { Name: 'Español', Id: 5, langId: 'es' },
    ru: { Name: 'Русский', Id: 6, langId: 'ru' },
    pt: { Name: 'Português', Id: 7, langId: 'pt' },
    ko: { Name: '한국어', Id: 8, langId: 'ko' },
    ja: { Name: '日本語', Id: 9, langId: 'ja' },
    zhHant: { Name: '繁体', Id: 10, langId: 'zhHant' },
    tr: { Name: 'Türkçe', Id: 12, langId: 'tr' },
};

export const SNACKBAR_DURATION = 4000;

export const PAGES = [
    'dashboard',
    'featuredOffers',
    'event',
    'livingSkulls',
    'myProfile',
    'myFaction',
    'blog',
];

export const FACTION_CHECK_IN_PROGRESS_MAX_POINT = 210;

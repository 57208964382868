import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress as MUICircularProgress, useTheme } from '@mui/material';
import { Box } from 'style-guide';

const CircularProgress = ({ height, progress, isExpired }) => {
    const theme = useTheme();

    return (
        <>
            <Box
                position="absolute"
                height={height}
                sx={{
                    '& circle': {
                        stroke: theme.palette.grey[700],
                        strokeWidth: 5,
                        r: 19.5,
                    },
                }}
            >
                <MUICircularProgress size={height} variant="determinate" value={100} />
            </Box>
            <Box
                height={height}
                sx={{
                    '& circle': {
                        stroke: isExpired ? theme.palette.text[500] : theme.palette.yellow[700],
                        strokeWidth: 5,
                        r: 19.5,
                    },
                }}
            >
                <MUICircularProgress size={height} variant="determinate" value={progress} />
            </Box>
        </>
    );
};

CircularProgress.propTypes = {
    height: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    isExpired: PropTypes.bool.isRequired,
};

export default CircularProgress;

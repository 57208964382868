import {
    TRANSLATION_QUERIES,
    TRANSLATIONS_API_KEY,
    TRANSLATIONS_URI,
} from 'constants/api.constants';
import { LANGUAGES } from 'constants/project.constants';
import { getStorageItem } from 'helpers/localStorage';
import { fetch } from 'services';
import { translationsSlice } from './translationSlice';

const setTranslationLoading = (page, loading) => {
    const { setLoading } = translationsSlice.actions;

    return (dispatch) => {
        dispatch(
            setLoading({
                page,
                loading,
            }),
        );
    };
};

const setLanguage = (currentLanguage) => (dispatch) => {
    const { setSelectedLanguage } = translationsSlice.actions;
    dispatch(setSelectedLanguage(currentLanguage));
};

const getTranslations = (page) => {
    const { setTranslations } = translationsSlice.actions;
    return async (dispatch) => {
        dispatch(setTranslationLoading(page, true));
        try {
            const { data: translations } = await fetch({
                url: TRANSLATIONS_URI,
                endPoint: `/records?columnIds=page,${
                    LANGUAGES[getStorageItem('language')].langId
                }&query=${TRANSLATION_QUERIES[page]}`,
                headers: {
                    Authorization: `ApiKey ${TRANSLATIONS_API_KEY}`,
                },
            });

            translations.length &&
                dispatch(setTranslations(getConvertedTranslations(translations)));
        } catch (error) {
            import(`../../locales/global/${page}.json`).then((response) => {
                dispatch(setTranslations(response.default));
            });
        }
        dispatch(setTranslationLoading(page, false));
    };
};

export const getConvertedTranslations = (translations) => {
    const convertedTranslations = {};
    translations.forEach((translation) => {
        const { id } = translation;
        translation.cells?.forEach((item) => {
            convertedTranslations[`${id}`] = {
                ...convertedTranslations[`${id}`],
                [item?.columnId]: item.value,
            };
        });
    });

    return convertedTranslations;
};

export const translationsOp = {
    getTranslations,
    setLanguage,
};

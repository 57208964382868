import React from 'react';
import PropTypes from 'prop-types';

import { Avatar as MUIAvatar, useTheme } from '@mui/material';

const Avatar = ({ alt, src, size, hasBorder }) => {
    const theme = useTheme();

    return (
        <MUIAvatar
            alt={alt}
            src={src}
            sx={{
                border: hasBorder ? `1px solid ${theme.palette.text[400]}` : null,
                width: size,
                height: size,
                boxSizing: 'border-box',
            }}
        />
    );
};

Avatar.propTypes = {
    alt: PropTypes.string.isRequired,
    src: PropTypes.string,
    size: PropTypes.number,
    hasBorder: PropTypes.bool,
};

Avatar.defaultProps = {
    src: null,
    size: 32,
    hasBorder: false,
};

export default Avatar;

import { createSelector } from 'reselect';

const featuredOffersSelector = (state) => state.featuredOffers;

const isLoadingSelector = createSelector(
    [featuredOffersSelector],
    (featuredOffers) => featuredOffers.isLoading,
);
const isExclusiveOffersLoadingSelector = createSelector(
    [featuredOffersSelector],
    (featuredOffers) => featuredOffers.exclusiveOffersLoading,
);
const exclusiveOffersSelector = createSelector(
    [featuredOffersSelector],
    (featuredOffers) => featuredOffers.exclusiveOffers,
);
const featuredOffersItemsSelector = createSelector(
    [featuredOffersSelector],
    (featuredOffers) => featuredOffers.featuredOffers,
);
const xsollaTokenSelector = createSelector(
    [featuredOffersSelector],
    (featuredOffers) => featuredOffers.xsollaToken,
);

export const featuredOffersSel = {
    isLoadingSelector,
    featuredOffersItemsSelector,
    xsollaTokenSelector,
    exclusiveOffersSelector,
    isExclusiveOffersLoadingSelector,
};

import React from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'hooks';

import { Icon, Typography, Box } from 'style-guide';
import { StyledMenu } from './Menu.styled';

import MenuEffect from 'assets/images/menu-effect.svg';

const Menu = ({
    hasEffect,
    menuContent,
    onCloseHandler,
    submenuOpened,
    submenuContent,
    onBackHandler,
    variant,
    handleRegionSelect,
    currentRealm,
    userName,
}) => {
    const [t] = useTranslation();
    const {
        palette: { text, black, grey, yellow },
        breakpoints,
    } = useTheme();
    const isMobile = useMediaQuery(() => breakpoints.down('sm'));

    return (
        <ClickAwayListener onClickAway={(e) => onCloseHandler(e)}>
            <div>
                <StyledMenu className={variant}>
                    {hasEffect && (
                        <Box
                            sx={{
                                backgroundImage: `url(${MenuEffect})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                            }}
                        />
                    )}
                    {isMobile && (
                        <Box
                            sx={{
                                p: 3,
                                borderBottom: `1px solid ${black[300]}`,
                                backgroundColor: black[400],
                                borderRadius: '6px 6px 0 0',
                                pointerEvents: 'none',
                            }}
                        >
                            <Typography variant="p12" fontWeight="bold" color={text[300]}>
                                {userName}
                            </Typography>
                        </Box>
                    )}
                    {submenuOpened ? (
                        <>
                            <Box
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        '& p': {
                                            color: text[300],
                                        },
                                        '& i': {
                                            color: text[300],
                                        },
                                    },
                                }}
                                display="flex"
                                alignItems="center"
                                p={3}
                                pl={2}
                                position="relative"
                                onClick={onBackHandler}
                            >
                                <Icon name="arrow-left" color={grey[600]} size={12} />
                                <Box ml={1}>
                                    <Typography fontWeight="bold" color={grey[600]} variant="p12">
                                        {t('back')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                maxHeight={350}
                                sx={{
                                    overflowY: 'auto',
                                    scrollbarWidth: 'thin',
                                    '&::-webkit-scrollbar': {
                                        display: 'block',
                                        width: '2px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: black[500],
                                        marginBottom: '6px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: grey[800],
                                    },
                                }}
                            >
                                {submenuContent.map((menuItem, index) => {
                                    return (
                                        <Box
                                            key={menuItem + index}
                                            onClick={() => {
                                                handleRegionSelect(menuItem.Name, menuItem.Id);
                                            }}
                                            sx={[
                                                {
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    position: 'relative',
                                                    width: '100%',
                                                    p: 3,
                                                    color:
                                                        menuItem.Id === currentRealm && yellow[700],
                                                },
                                                {
                                                    '&:hover': {
                                                        backgroundColor: black[400],
                                                    },
                                                },
                                            ]}
                                        >
                                            <Typography
                                                variant="p10"
                                                fontWeight="bold"
                                                color={
                                                    currentRealm === menuItem.Id
                                                        ? text[300]
                                                        : text[800]
                                                }
                                            >
                                                {menuItem.Name} {t('region')}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </>
                    ) : (
                        <Box>
                            {menuContent.map((menuItem, index) => {
                                const { id, content, hasSubmenu, callback } = menuItem;

                                return (
                                    <Box
                                        key={id}
                                        onClick={callback}
                                        sx={[
                                            {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                position: 'relative',
                                                width: '100%',
                                                p: 3,
                                                cursor: 'pointer',
                                            },

                                            {
                                                '&:hover': {
                                                    backgroundColor: black[400],
                                                },
                                            },
                                        ]}
                                    >
                                        <Typography variant="p12" color={text[300]}>
                                            {content}
                                        </Typography>
                                        {hasSubmenu && (
                                            <Icon name="arrow-right" color={grey[600]} size={12} />
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </StyledMenu>
            </div>
        </ClickAwayListener>
    );
};

Menu.propTypes = {
    userName: PropTypes.string.isRequired,
    variant: PropTypes.string,
    hasEffect: PropTypes.bool,
    submenuOpened: PropTypes.bool,
    menuContent: PropTypes.array,
    submenuContent: PropTypes.array,
    onCloseHandler: PropTypes.func,
    onBackHandler: PropTypes.func,
    handleRegionSelect: PropTypes.func,
    currentRealm: PropTypes.number,
};

Menu.defaultProps = {
    variant: 'bottom', // 'left', 'top', 'right',
    submenuOpened: false,
    hasEffect: true,
    menuContent: [],
    submenuContent: [],
};

export default Menu;

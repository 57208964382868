import React from 'react';
import PropTypes from 'prop-types';

import { GlobalStyles as MUIGlobalStyles, useTheme } from '@mui/material';

const GlobalStyles = ({ authenticated, online }) => {
    const theme = useTheme();
    const {
        palette: { black, text },
    } = theme;

    return (
        <MUIGlobalStyles
            styles={`
                html {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    ::-webkit-scrollbar {
                        display: 'none',
                        width: 0,
                    }
                }
                ::-webkit-scrollbar {
                    display: none;
                    width: 0;
                }
                body {
                    margin: 0;
                    background: ${black[800]};
                    color: ${text[300]};
                    font-family: helvetica, sans-serif;
                    min-height: 100vh;
                    overflow-x: hidden;
                    iframe {
                        display: ${!authenticated || !online ? 'none' : 'block'};
                    }
                }
                a {
                    text-decoration: none;
                    color: inherit;
                }
                input {
                    outline: none;
                }
                * {
                    box-sizing: border-box;
                }

                #ot-sdk-btn-floating.ot-floating-button {
                    display: none;
                },

                .text-truncate {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .user-select-none {
                    user-select: none;
                    cursor: default;
                    pointer-events: none;
                }
                @property --rotate {
                    syntax: "<angle>";
                    initial-value: 0deg;
                    inherits: false;
                }
                .card-animation::before {
                    content: "";
                    border-radius: 10px;
                    background-image: linear-gradient(var(--rotate), #FEC455 0%,#62543A 40%, #62543A 60%, #FEC455 100%);
                    position: absolute;
                    z-index: -1;
                    animation: spin 4s linear infinite;
                    filter: drop-shadow(4px 4px 15px rgba(255, 205, 108, 0.1));
                }
                @keyframes spin {
                    0% {
                        --rotate: 0deg;
                    }
                    100% {
                        --rotate: 360deg;
                    }
                }

                // TODO: remove cursor pointer
                .cursor-pointer {
                    cursor: pointer;
                }`}
        />
    );
};

GlobalStyles.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    online: PropTypes.bool.isRequired,
};

export default GlobalStyles;

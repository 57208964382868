import React from 'react';
import PropTypes from 'prop-types';

import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { globalSel } from 'store/global';

import { Badge, Box, Icon, Typography } from 'style-guide';
import { useMediaQuery, useTheme } from '@mui/material';
import { StyledLeftSidebarNav } from './LeftSidebarNav.styled';

import { useTranslation } from 'hooks';
import { addLanguageCodeToLink } from 'helpers/utils';

const LeftSidebarNav = ({ navigationList, setOpenLeftSidebar }) => {
    const [t] = useTranslation();
    const location = useLocation();
    const theme = useTheme();

    const globalConfig = useSelector(globalSel.globalConfigSelector);
    const md = useMediaQuery(theme.breakpoints.down('md'));

    const activeClassAdd = (hrefItem) => {
        const blogPageActiveCondition =
            hrefItem === '/blog' && location.pathname.includes('/blog/');

        return blogPageActiveCondition;
    };

    return (
        <StyledLeftSidebarNav>
            <Box p={0} m={0} pb={16} onClick={md ? () => setOpenLeftSidebar(false) : null}>
                {navigationList.map((navItem) => {
                    if (!globalConfig[navItem.globalConfig]) {
                        return false;
                    }

                    return (
                        <NavLink
                            exact
                            activeClassName="active-link"
                            key={navItem.id}
                            to={addLanguageCodeToLink(navItem.href)}
                            className={
                                activeClassAdd(navItem.href) ? 'nav-link active-link' : 'nav-link'
                            }
                        >
                            <Box display="flex" alignItems="center" py={3} px={5}>
                                <Box mr={3}>
                                    <Icon size={18} name={navItem.iconClassName} />
                                </Box>

                                <Box width={140} mr={3} className="item-name">
                                    <Typography variant="p14" color={theme.palette.text[600]}>
                                        {t(navItem.name)}
                                    </Typography>
                                </Box>
                                {navItem.globalConfig !== 'factionProfile' && navItem.hasBadge ? (
                                    <Badge>new</Badge>
                                ) : null}
                                {navItem.globalConfig === 'factionProfile' && navItem.hasBadge ? (
                                    <Badge>check.in</Badge>
                                ) : null}
                            </Box>
                        </NavLink>
                    );
                })}
            </Box>
            <hr />
        </StyledLeftSidebarNav>
    );
};

LeftSidebarNav.defaultProps = {
    setOpenLeftSidebar: () => {},
};

LeftSidebarNav.propTypes = {
    navigationList: PropTypes.array.isRequired,
    setOpenLeftSidebar: PropTypes.func,
};

export default LeftSidebarNav;

import { combineReducers } from '@reduxjs/toolkit';

import globalSlice from './global';
import factionSlice from './faction';
import blogSlice from './blog';
import featuredOffersSlice from './featured-offers';
import playerSlice from './player';
import eventsSlice from './events';
import livingSkullsSlice from './livingSkullsShop';
import translationsSlice from './translations';

export const reducers = () =>
    combineReducers({
        global: globalSlice.reducer,
        faction: factionSlice.reducer,
        blog: blogSlice.reducer,
        featuredOffers: featuredOffersSlice.reducer,
        player: playerSlice.reducer,
        events: eventsSlice.reducer,
        livingSkulls: livingSkullsSlice.reducer,
        translations: translationsSlice.reducer,
    });

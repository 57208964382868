import axios from 'axios';

import { API_URL } from 'constants/api.constants';
import { DEFAULT_HEADERS } from 'constants/defaultHeaders.constant';

import { getStorageItem, removeStorageItem, setStorageItem } from 'helpers/localStorage';

const refreshAccessToken = async (refreshToken) => {
    try {
        const { data } = await axios.post(`${API_URL}/auth/refresh-token`, {
            refreshToken,
        });

        setStorageItem('accessToken', data.accessToken);
        setStorageItem('refreshToken', data.refreshToken);

        return data.accessToken;
    } catch (error) {
        removeStorageItem('accessToken');
        removeStorageItem('refreshToken');
    }
};

const axiosInstance = axios.create({
    baseURL: API_URL,
});

const fetch = ({
    url = API_URL,
    endPoint = '/',
    body = {},
    method = 'GET',
    headers = {},
    referrerPolicy = { referrerPolicy: 'no-referrer-when-downgrade' },
}) => {
    const config = {
        method,
        url: `${url}${endPoint}`,
        data: body,
    };

    config.headers = {
        ...DEFAULT_HEADERS,
        ...headers,
        ...referrerPolicy,
    };

    if (method === 'GET') {
        config.params = body;
    }

    return axiosInstance(config);
};

axiosInstance.interceptors.request.use(
    (config) => {
        const token = getStorageItem('accessToken');

        if (token && config.url.startsWith(API_URL)) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        if (getStorageItem('language')) {
            //when selected zh-Hant lang gridly read key as zhHant and server as za-Hant
            const currentLanguage =
                getStorageItem('language') === 'zhHant' ? 'zh-Hant' : getStorageItem('language');
            config.headers['lang'] = currentLanguage;
        }

        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';

        return config;
    },
    (error) => {
        Promise.reject(error).then((r) => r);
    },
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const refreshToken = getStorageItem('refreshToken');

        if (error.response.status === 401 && !originalRequest._retry && refreshToken) {
            originalRequest._retry = true;
            const accessToken = await refreshAccessToken(refreshToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            return axiosInstance(originalRequest);
        }

        if (error.response.status === 302 && refreshToken) {
            const realmEvent = new Event('realm');
            window.dispatchEvent(realmEvent);
        }

        if (error.response) {
            error.data =
                error.response.status === 500
                    ? { message: 'Something went wrong, please try again.' }
                    : error.response.data;
        } else {
            error.data = {
                message: 'Something went wrong, please try again.',
            };
        }

        return Promise.reject(error.response);
    },
);

export { fetch, refreshAccessToken };

import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';
import { useTranslation } from 'hooks';
import { Box, Typography } from '../index';

const Badge = ({ children }) => {
    const [t] = useTranslation();
    const theme = useTheme();

    return (
        <Box
            display="flex"
            sx={{
                height: 20,
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: '2px',
                px: 2,
                borderRadius: '2px',
                ml: 2,
            }}
        >
            <Typography variant="p10" color={theme.palette.text[300]} fontWeight="bold" uppercase>
                {t(children)}
            </Typography>
        </Box>
    );
};

Badge.propTypes = {
    children: PropTypes.string.isRequired,
};

export default Badge;

import {
    API_ENV,
    APP_REDIRECT_URI,
    SCOPELY_LOGIN_CLIENT_ID,
    SCOPELY_LOGIN_ENDPOINT,
    VOLUUM_API_KEY,
    VOLUUM_CAMPAIGN_ID,
    VOLUUM_OFFER_ID,
} from './constants/api.constants';

import { BrowserTracker, BuildType, Settings } from '@scopely/web-sdk-analytics';
import { AttributionHooks, VoluumLandingHookAction, VoluumSettings } from '@scopely/web-sdk-ua';
import { Config, OktaProviderConfig, ScopelyId } from '@scopely/web-sdk-scopelyid';
import { HelpshiftExtension } from '@scopely/web-sdk-helpshift';

const buildType = API_ENV === 'prod' ? BuildType.Production : BuildType.Debug;
const settings = new Settings(VOLUUM_API_KEY, buildType);
const tracker = new BrowserTracker(settings);

tracker.register();

const voluum = () => {
    const hook = new AttributionHooks(tracker)
        // Example of Voluum usage. If no voluum attribution is needed, this can be skipped
        .withUserLanding(
            new VoluumLandingHookAction(new VoluumSettings(VOLUUM_CAMPAIGN_ID, VOLUUM_OFFER_ID)),
        );

    hook.userLanding();

    window.addEventListener('load', () => hook.appOpen());
};

export default voluum;

const scopelyIdConfig = new Config(
    new OktaProviderConfig(
        SCOPELY_LOGIN_ENDPOINT,
        SCOPELY_LOGIN_CLIENT_ID,
        APP_REDIRECT_URI,
        APP_REDIRECT_URI,
    ),
    'https://heimdall.aprod.scopely.io',
);
const scopelyId = new ScopelyId(scopelyIdConfig, tracker);

const PLATFORM_ID = 'scopely_platform_20210125150323668-483b5e9f2badcda';
const DOMAIN = 'scopely';
const LANGUAGE = 'en';

window.helpshiftConfig = {
    platformId: PLATFORM_ID,
    domain: DOMAIN,
    language: LANGUAGE,
};

const helpshiftExtension = new HelpshiftExtension(
    window.Helpshift,
    window.helpshiftConfig,
    tracker,
);

export { helpshiftExtension, tracker, scopelyId };

import React, { useEffect } from 'react';

import { SNACKBAR_DURATION } from 'constants/project.constants';

import { useDispatch, useSelector } from 'react-redux';
import { globalOp, globalSel } from 'store/global';

import { useTheme, useMediaQuery } from '@mui/material';
import { Box, Icon, Typography } from 'style-guide';

import { useTranslation } from 'hooks';

import effect from 'assets/images/menu-effect.svg';

const SnackBar = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const {
        palette: { yellow, red, black, grey, text },
        breakpoints,
    } = theme;
    const isMobile = useMediaQuery(breakpoints.down('md'));

    const dispatch = useDispatch();
    const snackbar = useSelector(globalSel.snackbarSelector);
    const { open, message, variant } = snackbar;

    const renderIcon = {
        success: <Icon color={yellow[800]} name="checkbox" size={20} />,
        error: <Icon color={red[600]} name="exclamation" size={20} />,
    };

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                dispatch(globalOp.handleCloseSnackbar());
            }, SNACKBAR_DURATION);
        }
    }, [dispatch, open]);

    const handleCloseSnackbar = () => {
        dispatch(globalOp.handleCloseSnackbar());
    };

    return (
        open && (
            <Box
                sx={{
                    position: 'fixed',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: 300,
                    top: 80,
                    left: '50%',
                    transform: isMobile ? 'translateX(-50%)' : 'translateX(-50% + 240px)',
                    zIndex: 1600,
                    p: 2,
                    backgroundImage: `url(${effect})`,
                    backgroundColor: black[300],
                    border: `4px solid ${grey[600]}`,
                    borderRadius: '4px',
                    animation: 'fade 0.5s linear',
                    gap: 6,
                    '@keyframes fade': {
                        from: {
                            opacity: 0,
                        },
                        to: {
                            opacity: 1,
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        width: 34,
                        height: 34,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255,255,255,0.1)',
                    }}
                >
                    {renderIcon[variant]}
                </Box>
                <Typography variant="p14" color={text[300]} width={180}>
                    {t(message)}
                </Typography>
                <Box
                    onClick={handleCloseSnackbar}
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    <Icon size={14} name="close" color={grey[500]} />
                </Box>
            </Box>
        )
    );
};

export default SnackBar;
